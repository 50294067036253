<template>
  <Loading v-if="isLoading" />
  <div v-else class="scaleCalendar">
    <PageTitle icon="fa fa-calendar" main="Agenda dos Profissionais" :sub="`Escala de Trabalho - ${patientsAdmission.length} Pacientes`" />
    <div class="scaleCalendar-page-tabs">
      <b-row>
        <b-col md="6" sm="12">
            <b-form-group label="Data:" label-for="date">
                <b-row>
                    <b-col md="4" sm="12">
                        <b-input type="date" size="sm" v-model="dateSelected" @change="getProfessionalsDay(true)"></b-input>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12">    
            <b-card :class="`card-${theme} mt-1`" :header="`Profissionais - ${lookupProfessionals.length}`" header-bg-variant="dark" header-text-variant="white">
                <b-button size="sm" v-if="dateSelected" @click="setModal('professionals')" v-b-modal.modal-prevent-closing variant="success" class="mb-3" 
                          v-b-popover.hover.top="'Adicionar Profissional'" @event="insertProfessionalScale">
                    <i class="fa fa-plus"></i>
                </b-button>  

                <b-button size="sm" v-if="dateSelected" @click="distributionScale" v-b-modal.modal-prevent-closing variant="info" class="mb-3 ml-3" 
                        v-b-popover.hover.top="'Distribuir'">
                    <i class="fa fa-retweet"></i>
                </b-button>

                <b-button size="sm" v-if="dateSelected" @click="print" variant="secondary" v-b-popover.hover.top="'Imprimir Todos os Atendimentos'" class="mb-3 ml-3">
                    <i class="fa fa-print"></i>
                </b-button>

                <Lookup ref="Lookup" title="Profissionais" :list="professionals" :fields="pfields" :invalidFeedback="invalidFeedback" 
                        @event="insertProfessionalScale" resultTable="professionalInsert" v-if="modal === 'professionals'"></Lookup>                  

                <b-table ref="selectableTableProfessionals" head-variant="light" outlined hover :items="lookupProfessionals" 
                        :fields="fields" responsive="sm" fixed selectable :select-mode="selectMode" 
                        @row-selected="onRowSelectedProfessional" striped>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="removeProfessional(row.item)" v-b-popover.hover.top="'Excluir Profissional'">
                            <i class="fa fa-trash"></i>
                        </b-button>
                        <b-button size="sm" variant="warning" @click="setModal('professionalsUpdate', row.item)" class="ml-2" 
                                v-b-popover.hover.top="'Substituir Profissional'"
                                v-b-modal.modal-prevent-closing @event="updateProfessional">
                            <i class="fa fa-refresh"></i>
                        </b-button>                      
                    </template>
                </b-table>                            

                <Lookup ref="Lookup" title="Profissionais" :list="professionals" :fields="pUpdatefields" :invalidFeedback="invalidFeedback" 
                        @event="updateProfessional" resultTable="professionalsUpdate" v-if="modal === 'professionalsUpdate'"></Lookup>                            

                <div v-if="resultLookupProfessional.length == 0 && loadingProfessionals" class="d-flex justify-content-center mb-3">    
                    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
                </div>
            </b-card>
        </b-col>
        <b-col>
            <b-card :class="`card-${theme} mt-1`" :header="`Pacientes - ${lookupPatients.length}`" header-bg-variant="dark" header-text-variant="white">
                <b-button size="sm" v-if="professionalSelected" @click="setModal('patients')" v-b-modal.modal-prevent-closing variant="success" class="mb-3" 
                          v-b-popover.hover.top="'Adicionar Paciente'" @event="insertPatientScale">
                    <i class="fa fa-plus"></i></b-button>   

                <Lookup ref="Lookup" title="Pacientes" :list="patientsAdmission" :fields="pafields" :invalidFeedback="invalidFeedback" 
                        resultTable="patientScale" v-if="modal === 'patients'" @event="insertPatientScale"></Lookup> 

                <b-table ref="selectableTablePatients" head-variant="light" outlined hover :items="lookupPatients" 
                        :fields="patientfields" responsive="sm" fixed striped>
                    <template v-slot:cell(actions)="row">
                        <b-button v-b-modal.modal-addAttendance size="sm" variant="info" @click="setModal('addAttendance', row)" v-b-popover.hover.top="'Adicionar Atendimento'">
                            <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button class="ml-2" size="sm" variant="danger" @click="removePatient(row.item)" v-b-popover.hover.top="'Excluir'">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </template>
                </b-table>
                
                <b-modal id="modal-addAttendance" title="Tipos de Atendimento" size="lg" ok-only>
                    <b-table head-variant="light" outlined hover :fields="fieldsTALookup" responsive="sm" id="my-table" :items=listAttendancesPatient fixed></b-table>

                    <b-table head-variant="light" outlined hover :fields="fieldsTLookup" responsive="sm" id="my-table" :items=resultTypeServices
                            fixed selectable select-mode="multi" @row-selected="onRowSelectedAttendances">
                    </b-table>

                    <template #modal-footer="{ }">
                        <b-button size="sm" variant="success" @click="addAttendance()">
                            Salvar
                        </b-button>
                    </template>                    
                </b-modal>                      

                <div v-if="lookupPatients.length == 0 && loadingPatients" class="d-flex justify-content-center mb-3">
                    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
                </div>
            </b-card>    
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import Lookup from '../default/Lookup'
import { mapState } from "vuex"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'
import Loading from "../template/Loading"

export default {
    name: "ScaleCalendar",
    components: { PageTitle, Lookup, Loading },
    data: function() {
        return {
            theme: null,
            isLoading: false,
            selectMode: 'single',
            scale: {},
            newScale: {},
            dateSelected: null,
            professionals: [],      
            pSelectedUpdate: {},
            professionalSelected: null,
            patientsAdmission: [],
            timeBetweenAttendance: 60,
            settings: null,
            invalidFeedback: '',
            loadingProfessionals: false,
            loadingPatients: false,
            listAttendancesPatient: [],
            listAttendancesPatientSelected: [],
            fields: [                 
                { key: 'name', label: 'Nome', sortable: false },
                { key: 'cpf', label: 'CPF', sortable: false },
                { key: 'checkin', label: 'Hora Entrada', sortable: false },
                { key: 'checkout', label: 'Hora Saída', sortable: false },
                { key: 'actions', label: 'Ações' }
            ],          
            pfields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },            
                { key: 'checkin', label: 'Entrada', sortable: false },
                { key: 'checkout', label: 'Saída', sortable: false },
            ],    
            pUpdatefields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },            
                { key: 'advice.number', label: 'Registro', sortable: true, },            
            ],                   
            pafields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },            
                { key: 'covenant', label: 'Convênio', sortable: true, },
                { key: 'descriptionAttendance', label: 'Atendimentos', sortable: true, },                
                { key: 'accommodationFull', label: 'Local de Internação', sortable: true, },
            ],                
            patientfields: [                 
                { key: 'name', label: 'Nome', sortable: false },
                { key: 'cpf', label: 'CPF', sortable: false, },
                { key: 'covenantName', label: 'Convênio', sortable: false, },
                { key: 'accommodationFull', label: 'Local de Internação', sortable: false, },
                { key: 'actions', label: 'Ações' }
            ],
            fieldsTALookup: [
                { key: "name", label: "Atendimentos Atuais", sortable: true },
            ],
            fieldsTLookup: [
                { key: "name", label: "Nome", sortable: true },
            ],
            modal: null,
            lookupProfessionals: [],
            lookupPatients: [],
            resultTypeServices: [],
            indexPatient: null
        }        
    },
    computed: mapState(["resultLookupProfessional", "resultLookupPatients", "user"]),
    methods: {
        async print() {
            try {
                let listTopics = []
                let listPatients = []
            
                for (let p of this.scale.professionals) {
                    if (p.attendance) {
                        if (p.attendance[0].patients.length > 0) {
                            listTopics.push(p.name)                              
                            
                            for (let pa of p.attendance[0].patients) {
                                if (pa) {
                                    if (pa.name) {
                                        pa.accommodationFull = `${pa.accommodation.building} -> ${pa.accommodation.floor} -> ${pa.accommodation.apartment}`

                                        pa.attendancesFull = ''

                                        for (let at of pa.attendances) {
                                            if (pa.attendancesFull == '') {
                                                pa.attendancesFull = at.name
                                            } else {
                                                pa.attendancesFull = `${pa.attendancesFull} - ${at.name}` 
                                            }
                                        }

                                        pa.key = p.name

                                        listPatients.push(pa)
                                    }
                                }
                            }
                        }
                    }
                }

                await this.generatePDF(listTopics, listPatients)
            } catch(e) {
                this.$toasted.global.defaultError({ msg: 'Erro ao gerar relatório.' })
            }
        },
        async generatePDF(listTopics, listPatients) {
            let contentPdf = {
                title: 'ATENDIMENTOS',
                format: 'A4',
                orientation: 'landscape',
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: [
                            {
                                original: 'name',
                                exibition: 'Nome'
                            },
                            {
                                original: 'covenantName',
                                exibition: 'Convênio'
                            },
                            {
                                original: 'admissionAttendanceNumber',
                                exibition: 'Nº Atendimento'
                            },                                                       
                            {
                                original: 'accommodationFull',
                                exibition: 'Local de Atendimento'
                            },                          
                            {
                                original: 'attendancesFull',
                                exibition: 'Tipos Atend.'
                            },
                            {
                                original: 'comments',
                                exibition: 'Obs.'
                            }                            
                        ],
                        breakPage: true,
                        list: listPatients
                    },
                ]
            }

            this.isLoading = true

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
                var file = new Blob([res.data], {type: 'application/pdf'})
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL)

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                   
        },        
        loadAdmissions() {
            try {
                this.isLoading = true

                const url = `${baseApiUrl}/admission/notreleased/0&0&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    let admissions = res.data   
                    
                    let descriptionAttendance = ''

                    for (let a of admissions) {                    
                        for (let at of a.attendance) {
                            let morning = at.morning ? 'MANHÃ ' : ''
                            let evening = at.evening ? 'TARDE ' : ''
                            let night = at.night ? 'NOITE. ' : ''
                            
                            descriptionAttendance = descriptionAttendance + at.name + ': ' + morning + evening + night
                        }                                

                        this.patientsAdmission.push(                        
                            {
                                _id: a.patient._id,
                                name: a.patient.name,
                                cpf: a.patient.cpf,
                                rg: a.patient.rg,
                                birthday: a.patient.birthday,
                                covenant: a.patient.covenant.name,
                                covenantId: a.patient.covenant._id,
                                covenantNumber: a.patient.covenant.number,
                                accommodation: a.accommodation,
                                accommodationFull: `${a.accommodation.building} -> ${a.accommodation.floor} -> ${a.accommodation.apartment} -> ${a.accommodation.name}`,
                                attendances: a.attendance,
                                admissionCode: a._id,
                                admissionAttendanceNumber: a.attendanceNumber,
                                admissionNumberGuide: a.numberGuide,
                                admissionPassword: a.password,
                                descriptionAttendance: descriptionAttendance,
                                comments: a.comments,
                                cellVariants: {
                                    name: '',
                                    cpf: '',
                                    covenant: '',
                                    descriptionAttendance: '',
                                    accommodationFull: ''
                                }
                            }                        
                        )

                        descriptionAttendance = ''
                    }     
                    
                    this.isLoading = false
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })          
            } catch(e) {
                this.isLoading = false
                console.log(e)
            }            
        },
        getProfessionalsDay(clear) {            
            this.loadingProfessionals = true

            this.modal = null

            this.lookupPatients = []

            if (clear) {
                this.lookupProfessionals = []                    
                this.professionalSelected = null
            }

            this.isLoading = true

            const url = `${baseApiUrl}/scale/date/${this.dateSelected}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.scale = res.data                               

                let namePatient                 
                
                if (!this.scale) {
                    this.scale = {
                      date: '',
                      professionals: []
                    }                                        
                } else {   
                    try {   
                        if (this.professionalSelected) {                            
                            for (let p of this.scale.professionals) {
                                if (p.cpf == this.professionalSelected.cpf) {
                                    for (let pa of p.attendance[0].patients) {
                                        this.lookupPatients.push(pa)

                                        namePatient = pa.name

                                        if (!pa.accommodation) {
                                            this.$toasted.global.defaultError({ msg: `Paciente: ${namePatient} sem local de atendimento definido.` })
                                        }

                                        pa.accommodationFull = `${pa.accommodation.building} -> ${pa.accommodation.floor} -> ${pa.accommodation.apartment} -> ${pa.accommodation.name}`

                                        let splBegin = pa.start.split(":")
                                        let beginMin = (Number(splBegin[0] * 60)) + Number(splBegin[1])

                                        pa.startCompare = beginMin

                                        if (pa.attendances) {
                                            pa.attendancesFull = ''                                        

                                            for (let af of pa.attendances) {
                                                if (pa.attendancesFull == '') {
                                                    pa.attendancesFull = af.name
                                                } else {
                                                    pa.attendancesFull = pa.attendancesFull + ' - ' + af.name
                                                }
                                            }
                                        }

                                        p.attendance[0].patients.sort((a,b) => a.startCompare - b.startCompare)
                                    }     
                                }
                            }       
                        }
                        
                        for (let pnm of this.scale.patientsNotMarked) {
                            for (let p of this.patientsAdmission) {
                                if (pnm.cpf == p.cpf) {
                                    p._cellVariants = {
                                        name: 'warning',
                                        cpf: 'warning',
                                        covenant: 'warning',
                                        descriptionAttendance: 'warning',
                                        accommodationFull: 'warning'
                                    }
                                }
                            }
                        }       

                        this.lookupProfessionals = this.scale.professionals
                    } catch(e) {
                        console.log(e)
                    }
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      

            this.loadingProfessionals = false
        },
        async getProfessionalNewScale(date, prof) {
            this.isLoading = true

            const url = `${baseApiUrl}/scale/date/${date}`
            await axios.get(url).then(res => {
                this.newScale = res.data                               
                
                if (!this.newScale) {
                    let profs = []

                    profs.push(prof)

                    this.newScale = {
                        date: date,
                        professionals: profs
                    }                                        
                } else {
                    this.newScale.professionals.push(prof)
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        },
        insertProfessionalScale(payload) {
            if (this.dateSelected && this.modal != null) {
                let index = this.lookupProfessionals.length - 1
                let finded = false                  
                
                for (let [i, p] of this.scale.professionals.entries()) {
                    if (p.cpf == payload.professionalSelected.cpf && i != index) {      
                        if (payload.professionalSelected.checkin >= p.checkin && payload.professionalSelected.checkin <= p.checkout) {
                            this.modal = null

                            finded = true

                            this.$toasted.global.defaultError({msg: 'Profissional já adicionado no dia e horário informados.'})                                                       
                        }                                        
                    }

                    if (finded) {
                        break
                    }
                }

                if (!finded && this.modal != null) {       
                    this.modal = null
                    
                    let prof = {
                        name: payload.professionalSelected.name,
                        cpf: payload.professionalSelected.cpf,
                        email: payload.professionalSelected.email,
                        specialities: payload.professionalSelected.specialities,
                        checkin: payload.professionalSelected.checkin,
                        checkout: payload.professionalSelected.checkout,
                        attendance: [
                            {
                                patients: []
                            }
                        ]
                    }    

                    this.scale.date = this.dateSelected                    
                    
                    const method = 'post'

                    let data = {
                        scale: this.scale._id,
                        professional: prof
                    }           
                                  
                    if (this.scale._id) {
                        this.isLoading = true
                        
                        axios[method](`${baseApiUrl}/scale/addProfessional/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                            this.scale.professionals.push(prof)   

                            this.$toasted.global.defaultSuccess()                                            
                            this.getProfessionalsDay(false)

                            this.isLoading = false
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })  
                    } else {
                       this.isLoading = true

                       axios[method](`${baseApiUrl}/scale/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.scale).then(() => {
                            this.isLoading = false
                            this.$toasted.global.defaultSuccess()
                            this.getProfessionalsDay(false)
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        }) 
                    }
                }            
            }
        },
        insertPatientScale(payload) {
            try {
                if (this.modal == 'patients') {
                    this.modal = ''

                    let interval = this.settings[0].params[4].value 
                    let nextHour = 0
                    let indexProfessional = 0

                    let start = 0 
                    let finded = false

                    let acm = {}
                    let covenantId = ''
                    let covenantNumber = ''
                    let admissionCode = ''
                    let admissionAttendanceNumber = ''
                    let admissionNumberGuide = ''
                    let admissionPassword = ''
                    let attendances = ''
                    let comments = ''

                    Moment.locale('pt-br')

                    this.scale.professionals.find((item, i) => {
                        if (!this.professionalSelected) {
                            this.$toasted.global.defaultError({ msg: 'Selecione o profissional' })
                        } else {
                            if (item.cpf == this.professionalSelected.cpf && item.checkin == this.professionalSelected.checkin) {
                                indexProfessional = i                        

                                if (this.scale.professionals[indexProfessional].attendance) {
                                    if (this.scale.professionals[indexProfessional].attendance[0].patients.length > 0) {
                                        let fLast = this.scale.professionals[indexProfessional].attendance[0].patients[0].start.split(":")
                                        let last = (Number(fLast[0] * 60)) + Number(fLast[1])

                                        let splCheckin = this.scale.professionals[indexProfessional].checkin
                                        let beginChekinMin = (Number(splCheckin[0] * 60)) + Number(splCheckin[1]) 

                                        for (let pa of this.scale.professionals[indexProfessional].attendance[0].patients) {
                                            delete pa.startCompare

                                            let splBegin = pa.start.split(":")
                                            let beginMin = (Number(splBegin[0] * 60)) + Number(splBegin[1])

                                            if (!finded) {
                                                if (!(beginMin - last == Number(interval)) && (beginMin - Number(interval) >= beginChekinMin * 60)) {
                                                    start = Moment(pa.start, 'hh:mm:ss').subtract(Number(interval), 'minutes').format('LTS')                
                                                    finded = true
                                                }
                                            }

                                            last = beginMin
                                        }
                                    }                                            
                                }
                            }
                        }                   
                    })
                                   
                    if (this.scale.professionals[indexProfessional].attendance) {
                        if (start == 0 && this.scale.professionals[indexProfessional].attendance[0].patients.length == 0) {
                            start = Moment(this.professionalSelected.checkin, 'hh:mm:ss').format('LTS')
                        } else if (start == 0 && this.scale.professionals[indexProfessional].attendance[0].patients.length > 0) {
                            nextHour = this.scale.professionals[indexProfessional].attendance[0].patients.length * interval

                            start = Moment(this.professionalSelected.checkin, 'hh:mm:ss').add(nextHour, 'minutes').format('LTS')                    
                        }
                    } else {
                        start = Moment(this.professionalSelected.checkin, 'hh:mm:ss').format('LTS')
                    }

                    let finish = Moment(start, 'hh:mm:ss').add(Number(interval), 'minutes').format('LTS')        
                    
                    for (let ad of this.patientsAdmission) {
                        if (ad.admissionAttendanceNumber == payload.patientSelected.admissionAttendanceNumber) {
                            covenantId = ad.covenantId
                            covenantNumber = ad.covenantNumber
                            acm = ad.accommodation,
                            admissionCode = ad.admissionCode,
                            admissionAttendanceNumber = ad.admissionAttendanceNumber
                            admissionNumberGuide = ad.admissionNumberGuide,
                            admissionPassword = ad.admissionPassword,
                            attendances = ad.attendances,
                            comments = ad.comments
                        }   
                    }

                    let diff = 0

                    Moment.locale('pt-br')

                    if (payload.patientSelected.birthday.includes('/')) {
                        diff = parseInt(Moment(payload.patientSelected.birthday, "DD/MM/YYYY").fromNow({ withoutSuffix: false }).split("anos")[0])
                    } else {
                        diff = parseInt(Moment(payload.patientSelected.birthday, "YYYYMMDD").fromNow({ withoutSuffix: false }).split("anos")[0])
                    }

                    let adult = false

                    if (diff > Number(this.settings[0].params[8].value)) {
                        adult = true
                    }                    
                  
                    let patientAdd = {
                        _id: payload.patientSelected._id,
                        name: payload.patientSelected.name,
                        cpf: payload.patientSelected.cpf,
                        birthday: payload.patientSelected.birthday,
                        covenantId: covenantId,
                        covenantNumber: covenantNumber,
                        covenantName: payload.patientSelected.covenant,
                        accommodation: acm,
                        admissionCode: admissionCode,
                        admissionAttendanceNumber: admissionAttendanceNumber,
                        admissionNumberGuide: admissionNumberGuide,
                        admissionPassword: admissionPassword,
                        attendances: attendances,
                        comments: comments,
                        start: start,
                        finish: finish,
                        startDone: null,
                        finishDone: null,
                        isAdult: adult                    
                    }

                    let numberAttendance = 0

                    if (patientAdd.attendances[0].morning) {
                        numberAttendance += 1
                    }

                    if (patientAdd.attendances[0].evening) {
                        numberAttendance += 1
                    }

                    if (patientAdd.attendances[0].night) {
                        numberAttendance += 1
                    }

                    const method = 'post'

                    if (numberAttendance > 1) {
                        this.$bvModal.msgBoxConfirm(`Deseja distribuir todos os atendimentos desse paciente para o profissional ${this.scale.professionals[indexProfessional].name}?`, {
                            title: 'Confirmação',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'Sim',
                            cancelTitle: 'Não',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        }).then(value => {
                            this.isLoading = true

                            if (value) {                            
                                let promises = [];

                                let i = 1                                

                                for (let y = 0; y < numberAttendance; y++) {
                                    if (i > 1) {
                                        patientAdd.start = Moment(patientAdd.start, 'hh:mm:ss').add(y * 60, 'minutes').format('LTS') 
                                        patientAdd.finish = Moment(patientAdd.finish, 'hh:mm:ss').add(y * 60, 'minutes').format('LTS')
                                    }

                                    if (!this.scale.professionals[indexProfessional].attendance) {
                                        this.scale.professionals[indexProfessional].attendance = [{ patients: [] }]
                                    }
                                    
                                    this.scale.professionals[indexProfessional].attendance[0].patients.push(patientAdd)

                                    let data = {
                                        scale: this.scale._id,
                                        indexProfessional: indexProfessional,
                                        cpfProfessional: this.scale.professionals[indexProfessional].cpf, 
                                        patient: patientAdd
                                    }                                    
                                        
                                    promises.push(axios[method](`${baseApiUrl}/scale/addPatient/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
                                        .then().catch(e => {
                                            this.lookupPatients = []

                                            this.isLoading = false
                                            this.$toasted.global.defaultError({ msg: e.response.data })
                                        }))     
                                                                                
                                    i += 1
                                }  
                                
                                Promise.all(promises).then(() => {
                                    this.$toasted.global.defaultSuccess({ msg: 'Atendimentos distribuídos com sucesso' })
                                    this.getProfessionalsDay(false)                                    
                                    this.isLoading = false
                                });                         
                            } else {
                                this.scale.professionals[indexProfessional].attendance[0].patients.push(patientAdd)                        

                                let data = {
                                    scale: this.scale._id,
                                    indexProfessional: indexProfessional,
                                    cpfProfessional: this.scale.professionals[indexProfessional].cpf, 
                                    patient: patientAdd
                                }
                                    
                                axios[method](`${baseApiUrl}/scale/addPatient/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
                                    .then(() => {
                                        this.$toasted.global.defaultSuccess()                                            
                                        this.getProfessionalsDay(false)
                                        this.isLoading = false
                                    })  
                                    .catch(e => {
                                        this.lookupPatients = []

                                        this.isLoading = false
                                        this.$toasted.global.defaultError({ msg: e.response.data })                                           
                                    })     
                            }
                        })
                    } else {
                        if (this.scale.professionals[indexProfessional].attendance) {
                            this.scale.professionals[indexProfessional].attendance[0].patients.push(patientAdd)
                        } else {
                            this.scale.professionals[indexProfessional].attendance = []    
                            
                            let patients = []                                            

                            this.scale.professionals[indexProfessional].attendance.push({ 'patients': patients })

                            this.scale.professionals[indexProfessional].attendance[0].patients.push(patientAdd)
                        }

                        let data = {
                            scale: this.scale._id,
                            indexProfessional: indexProfessional,
                            cpfProfessional: this.scale.professionals[indexProfessional].cpf, 
                            patient: patientAdd
                        }

                        this.isLoading = true
                            
                        axios[method](`${baseApiUrl}/scale/addPatient/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                            this.$toasted.global.defaultSuccess()                                            
                            this.getProfessionalsDay(false)
                            this.isLoading = false
                        }).catch(e => {
                            this.lookupPatients = []
                            
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })   
                    }
                }
            } catch(e) {
                console.log(e)
            }
        },
        onRowSelectedProfessional(itemSelected, removePatient) {
            this.loadingPatients = true

            if (itemSelected) {
                if (itemSelected.length > 0) {            
                    this.lookupPatients = []  

                    if (removePatient == undefined) {
                        if (itemSelected[0].attendance) {
                            for (let pa of itemSelected[0].attendance[0].patients) {     
                                pa.accommodationFull = `${pa.accommodation.building} -> ${pa.accommodation.floor} -> ${pa.accommodation.apartment} -> ${pa.accommodation.name}`,                
                                this.lookupPatients.push(pa)
                            }            
                        }

                        this.professionalSelected = itemSelected[0]
                    } else {
                        for (let pa of itemSelected) {
                            pa.accommodationFull = `${pa.accommodation.building} -> ${pa.accommodation.floor} -> ${pa.accommodation.apartment} -> ${pa.accommodation.name}`,
                            this.lookupPatients.push(pa)
                        }
                    }
                }
            }

            this.loadingPatients = false
        },
        setModal(param, objP) {
            this.modal = param

            if (objP && param != 'addAttendance') {
                this.pSelectedUpdate = objP
            }

            if (param == 'addAttendance') {
                this.resultTypeServices = []

                this.loadTypeServices(objP)
            }            
        },  
        loadTypeServices(patient) {   
            this.indexPatient = patient.index

            const url = `${baseApiUrl}/covenants/getInfoTypeServicePerName/${patient.item.covenantName}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then((res) => {
                this.resultTypeServices = []
                this.listAttendancesPatient = []

                if (Array.isArray(res.data)) {
                    for (let a of patient.item.attendances) {
                        this.listAttendancesPatient.push({
                            name: a.name
                        })
                    }

                    for (let d of res.data) {
                        let finded = false

                        this.listAttendancesPatient.find(elem => {
                            if (elem.name == d.name) {
                                finded = true
                            }
                        })

                        if (!finded) {
                            this.resultTypeServices.push(d)
                        }
                    }
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        updateProfessional(payload) {
            this.$bvModal.msgBoxConfirm('Deseja alterar o profissional?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    this.modal = ''
            
                    let index = -1
                  
                    this.scale.professionals.find((item, i) => {
                        if (item.cpf == this.pSelectedUpdate.cpf && item.checkin == this.pSelectedUpdate.checkin) {
                            if (index == -1) {
                                index = i

                                let data = {
                                    scale: this.scale,
                                    professionalOld: this.scale.professionals[index].name,
                                    professional: this.scale.professionals[index],
                                    indexProfessional: index
                                }        

                                const method = 'post'
                                
                                this.scale.professionals[index].name = payload.professionalSelected.name
                                this.scale.professionals[index].cpf = payload.professionalSelected.cpf
                                this.scale.professionals[index].adviceNumber = payload.professionalSelected.advice.number                    

                                this.isLoading = true

                                axios[method](`${baseApiUrl}/scale/updateProfessional/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                                    this.$toasted.global.defaultSuccess({msg: 'Agenda atualizada sucesso!'})
                                    this.getProfessionalsDay(true)
                                    this.isLoading = false
                                }).catch(() => {
                                    this.isLoading = false
                                    this.$toasted.global.defaultError({msg: 'Período já processado!'})
                                })                    
                            }
                        }
                    })
                }                
            }).catch()
        },
        removeProfessional(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover a agenda do profissional?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    let i = 0
                    let finded = false
                    let notationDone = false

                    for (let p of this.scale.professionals) {
                        if (p.cpf == itemSelected.cpf && p.checkin == itemSelected.checkin && !notationDone) {
                            finded = true

                            for (let a of p.attendance) {
                                for (let pa of a.patients) {
                                    if (pa.startDone) {
                                        notationDone = true
                                    }
                                }
                            }                            
                        }

                        if (!finded) {
                            i += 1
                        }                        
                    }                    

                    if (notationDone) {
                       this.$toasted.global.defaultError({msg: 'Existe apontamento realizado na agenda do profissional'}) 
                    } else {           
                        let data = {
                            scale: this.scale,
                            professional: this.scale.professionals[i],
                            indexProfessional: i
                        }                         
                        
                        const method = 'post'

                        this.isLoading = true
                        
                        axios[method](`${baseApiUrl}/scale/deleteProfessional/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                            this.isLoading = false
                            this.scale.professionals.splice(i, 1)
                            this.$toasted.global.defaultSuccess({msg: 'Profissional removido com sucesso!'})
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })                   
                    }
                }                
            }).catch()
        },
        removePatient(itemSelected) {
            let data = null

            this.$bvModal.msgBoxConfirm('Deseja remover o paciente da agenda?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                    
                    for (let [x, p] of this.scale.professionals.entries()) {
                        if (p.cpf == this.professionalSelected.cpf && p.checkin == this.professionalSelected.checkin) {                            
                            p.attendance[0].patients.find((item, i) => {
                                if (item.cpf == itemSelected.cpf && item.start == itemSelected.start) {
                                    if (item.startDone) {
                                        this.$toasted.global.defaultError({msg: 'Existe apontamento realizado para este paciente'})
                                    } else {                     
                                        data = {
                                            scale: this.scale,
                                            indexProfessional: x,
                                            indexPatient: i,
                                            patient: { name: item.name, cpf: item.cpf, start: item.start }
                                        }
                                    }
                                }
                            })

                            break
                        }
                    }

                    if (data) {
                        const method = 'post'
                                            
                        this.isLoading = true
                        
                        axios[method](`${baseApiUrl}/scale/deletePatient/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                            this.$toasted.global.defaultSuccess({msg: 'Paciente removido com sucesso!'})
                            this.getProfessionalsDay(false)
                            this.isLoading = false
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })
                    }
                }
            }).catch()
        },
        distributionScale() {
            let scaleDistribution = {
                date: this.dateSelected,
                professionals: this.lookupProfessionals 
            }

            let datePart = this.dateSelected.split('-')

            let today = new Date()

            let diffDate = Moment(new Date(`${datePart[0]}/${datePart[1]}/${parseInt(datePart[2])}`)).diff(new Date(`${today.getFullYear()}/${today.getMonth()+1}/${today.getDate()}`))
          
            if (diffDate < 0) {
                this.$toasted.global.defaultError({msg: 'Período já processado.'})  
            } else {
                this.$router.push({name: 'scaleDistribution', params: { scaleDistribution },})
            }
        },
        scaleAutomatic() {
            this.$bvModal.msgBoxConfirm('Deseja realizar a distribuição automática de atendimentos?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    let notationDone = false

                    for (let p of this.scale.professionals) {
                        for (let a of p.attendance) {
                            for (let pa of a.patients) {
                                if (pa.startDone) {
                                    notationDone = true
                                }
                            }
                        }
                    }

                    if (notationDone) {
                        this.$toasted.global.defaultError({msg: 'Existe apontamento realizado. Não será possível distribuir automaticamente'})
                    } else {
                        this.isLoading = true

                        axios['post'](`${baseApiUrl}/scaleAutomatic/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.scale).then(() => {
                            this.getProfessionalsDay(true)
                            this.$toasted.global.defaultSuccess({msg: 'Atendimentos distribuidos com sucesso'})

                            this.isLoading = false
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        }) 
                    }
                }
            }).catch()
        },
        onRowSelectedAttendances(listSelected) {
            this.listAttendancesPatientSelected = listSelected
        },
        addAttendance() {
            let indexProfessional = 0

            this.scale.professionals.find((item, i) => {
                if (item.cpf == this.professionalSelected.cpf && item.checkin == this.professionalSelected.checkin) {
                    indexProfessional = i
                }
            })            

            let data = {
                scale: this.scale,
                indexProfessional: indexProfessional,
                indexPatient: this.indexPatient,
                attendances: this.listAttendancesPatientSelected
            }

            axios['post'](`${baseApiUrl}/scale/addAttendancePatient/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                this.$toasted.global.defaultSuccess({msg: 'Atendimento(s) adicionado(s) com sucesso!'})
                this.getProfessionalsDay(false)
                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })            

            this.$bvModal.hide('modal-addAttendance')
        },
        loadSettings() {
            this.isLoading = true

            if (!this.user.admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.isLoading = false
                this.settings = res.data                
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        }              
    },    
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.lookupProfessionals = []    
        this.lookupPatients = []  

        this.loadSettings()     
        this.loadAdmissions()
    }      
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.qtd-patients {
    color: midnightblue;
}
</style>