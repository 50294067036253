<template>
    <Loading v-if="isLoading" />
    <div v-else class="batchGuidesANSProcessing">
        <PageTitle icon="fa fa-hashtag" main="ANS" sub="Geração de Guias" />
            <div class="batchguidesANS-page-tabs">
                <b-row>
                    <b-col md="8" sm="12" class="mt-3">
                        <b-card :class="`card-${theme}`" header="Informações" border-variant="dark" header-border-variant="white" header-bg-variant="dark" header-text-variant="white">
                            <b-row>
                                <b-col md="3" sm="12">
                                    <b-form-group label="Data Inicial:" label-for="bgans-dateInitial">
                                        <b-form-input size="sm" id="bgans-dateInitial" type="date" v-model="dateInitial" required />
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <b-form-group label="Data Final:" label-for="bgans-dateFinal">
                                        <b-form-input size="sm" id="bgans-dateFinal" type="date" v-model="dateFinal" required/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <b-form-group label="Convênio:" label-for="bgans-covenant">
                                        <b-form-select @change="loadAdmissions()" size="sm" v-model="selectedCovenant" :options="covenants"></b-form-select>
                                    </b-form-group>    
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card :class="`card-${theme} mt-2`" header="Pacientes" border-variant="dark" header-border-variant="white" header-bg-variant="dark" header-text-variant="white">
                            <b-button class="mt-2" size="sm" v-b-modal.modal-prevent-closing @click="setModal('patients')" variant="success" v-b-popover.hover.top="'Adicionar Paciente'">ADICIONAR</b-button>
                            <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="listPatients" :fields="fieldsPatiens" responsive="sm" id="my-table"
                                    striped fixed>
                                <template v-slot:cell(actions)="row">
                                    <b-button size="sm" variant="danger" @click="deletePatient(row.index)" v-b-popover.hover.top="'Remover'">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>

                        <Lookup ref="Lookup" title="Pacientes" :list="patientsAdmissionLookup" :fields="pafields" 
                            resultTable="patientScale" v-if="modal === 'patients'" @event="addPatient"></Lookup>

                        <b-button ref="btnSave" class="mt-2" size="sm" variant="warning" @click="searchBatchGuides" v-b-popover.hover.top="'Gerar Guias'">
                            <div class="d-flex align-items-center">
                                <b-spinner small v-if="generating" class="mr-2"></b-spinner>
                                <span>{{textBtnPrint}}</span>
                            </div>
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" sm="12" class="mt-2">
                        <b-progress id="progress" :max="max">
                            <b-progress-bar animated :value="value" :label="`${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
                        </b-progress>
                    </b-col>
                </b-row>              
  
              <b-modal id="modalGuides" title="Guias" size="xl" @ok="batchGuidesProcessing">
                  <b-table ref="modalGuides" head-variant="light" outlined hover :items="listGuides" 
                        :fields="fields" responsive="sm" fixed striped>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="info" @click="detailGuide(row.item)" v-b-popover.hover.top="'Detalhes'">
                            <i class="fa fa-eye"></i>
                        </b-button>
                        <b-button class="ml-2" size="sm" variant="danger" @click="deleteGuide(row.index)" v-b-popover.hover.top="'Remover'">
                            <i class="fa fa-trash"></i>
                        </b-button>                        
                    </template> 
                  </b-table>   
              </b-modal>

              <b-modal id="detailGuides" :title='`Detalhes da Guia - ${patientSelected}`' size="lg" ok-only>
                <b-button v-b-modal.modal-addAttendance size="sm" variant="success" @click="setModal('addAttendance')" v-b-popover.hover.top="'Adicionar Atendimento'">
                    <i class="fa fa-plus"></i>
                </b-button>
                <b-button size="sm" class="ml-2" variant="danger" v-b-popover.hover.top="'Remover Todos'" @click="removeAllAttendances">
                    <i class="fa fa-trash"></i>
                </b-button>                
                <b-table class="mt-2" ref="detailGuides" head-variant="light" outlined hover :items="listDetails" 
                    :fields="fieldsDetails" responsive="md" striped>
                    <template v-slot:cell(actions)="row">
                        <b-button class="ml-2" size="sm" variant="danger" @click="deleteAttendance(row.item)" v-b-popover.hover.top="'Remover'">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </template>
                </b-table>
                <hr>
                Totais:
                <b-table class="mt-2" ref="detailTotalGuides" head-variant="light" outlined hover :items="listTotalDetails" 
                    :fields="fieldsTotalDetails" responsive="md" striped>
                </b-table>                
              </b-modal>

              <b-modal id="modal-addAttendance" title="Tipos de Atendimento" size="lg" ok-only>
                <b-table head-variant="light" outlined hover :fields="fieldsTLookup" responsive="sm" id="my-table" :items=resultTypeServices
                    fixed selectable select-mode="multi" @row-selected="onRowSelectedAttendances">

                    <template v-slot:cell(date)="row">
                        <b-input size="sm" :value="new Date()" v-model="row.item.date" type="date"></b-input>
                    </template>

                    <template v-slot:cell(startDone)="row">
                        <b-input size="sm" v-model="row.item.startDone" type="text" v-mask="'##:##'" placeholder="HH:MM"></b-input>
                    </template>
                    
                    <template v-slot:cell(finishDone)="row">
                        <b-input size="sm" v-model="row.item.finishDone" type="text" v-mask="'##:##'" placeholder="HH:MM"></b-input>
                    </template>                    
                </b-table>

                <template #modal-footer="{ }">
                    <b-button size="sm" variant="success" @click="addAttendance()">
                        Salvar
                    </b-button>
                </template>                    
              </b-modal>

              <b-modal id="errorsGuides" title="Guias - Erros" @hidden="resetModal" size="xl" ok-only>
                <b-table ref="errorsGuides" head-variant="light" outlined hover :items="listErrorGuides" 
                    :fields="fieldsErrorsGuides" responsive="sm" fixed striped>
                </b-table>   
            </b-modal>
        </div>
    </div>
  </template>
  
  <script>
  import PageTitle from "../template/PageTitle"
  import Moment from 'moment'
  import { baseApiUrl, showError, userKey } from '@/global'
  import axios from 'axios'
  import Lookup from '../default/Lookup'
  import Loading from "../template/Loading"
  import util from '../default/util'
  
  export default {
      name: "batchGuidesANSProcessing",
      components: { PageTitle, Lookup, Loading },    
      data: function() {
        return {
            isLoading: false,
            theme: null,
            dateInitial: null,
            dateFinal: null,
            settings: null,            
            xml: null,
            patientSelected: '',
            listResult: [],
            listGuides: [],
            listDetails: [],
            listTotalDetails: [],
            listErrorGuides: [],            
            scales: [],
            selectedCovenant: null,
            covenants: [],
            listPatients: [],
            patientsAdmission: [],
            patientsAdmissionLookup: [],
            generating: false,
            textBtnPrint: 'GERAR',
            modal: null,
            resultTypeServices: [],
            listAttendancesPatientSelected: [],
            dataSelected: null,
            listTSErrors: [],
            fieldsTLookup: [
                { key: "name", label: "Nome", sortable: false },
                { key: "date", label: "Data", sortable: false },
                { key: "startDone", label: "Hora Inicial", sortable: false },
                { key: "finishDone", label: "Hora Final", sortable: false }
            ],
            fields: [                 
                { key: 'patient', label: 'Paciente', sortable: false },
                { key: 'patient', label: 'CPF', sortable: false },
                { key: 'requester', label: 'Solicitante', sortable: false }, 
                { key: 'actions', label: 'Ações' }
            ],
            fieldsDetails: [
                { key: 'date', label: 'Data', sortable: false },
                { key: 'attendance', label: 'Atendimento', sortable: false },
                { key: 'startDone', label: 'Hora Inicial', sortable: false },
                { key: 'finishDone', label: 'Hora Final', sortable: false },
                { key: 'count', label: 'Quantidade', sortable: false },
                { key: 'actions', label: 'Ações' }
            ],
            fieldsTotalDetails: [
                { key: 'attendance', label: 'Atendimento', sortable: false },
                { key: 'count', label: 'Quantidade', sortable: false },
            ],            
            fieldsPatiens: [
                { key: 'name', label: 'Nome', sortable: false },
                { key: 'cpf', label: 'CPF', sortable: false },
                { key: 'actions', label: 'Ações' }
            ],
            pafields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },            
                { key: 'covenant', label: 'Convênio', sortable: true, },
            ],            
            fieldsErrorsGuides: [
                { key: 'error', label: 'Descrição do Erro', sortable: true }
            ],
            job: null,
            jobID: '',
            jobResult: null,
            max: 100,
            value: 0
        }
      },  
      methods: {
        batchGuidesProcessing() {
            if (this.listResult.length == 0) {
                this.$toasted.global.defaultError({msg: 'Nenhum atendimento carregado'})
            } else {
                this.$bvModal.msgBoxConfirm('Deseja gerar as guias?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        let error = ''

                        this.listResult[0].listAttendancesGuides.find(elem => {
                            if (elem.valorUnitario === 0 && elem.count > 0) {
                                error = 'Existem tipos de atendimentos sem os dados necessários para o faturamento.'
                            }
                        })

                        if (error !== '') {
                            this.$toasted.global.defaultError({msg: error})
                        } else {
                            const data = {
                                listResult: this.listResult,
                                listGuides: this.listGuides
                            }

                            this.isLoading = true

                            const url = `${baseApiUrl}/guidesANS/generateBatchGuidesANS/${this.selectedCovenant._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                            axios.post(url, data).then(() => {
                                this.$router.go(-1)
                            }).catch(showError)
                        }
                    }
                })
            }
        },
        async searchBatchGuides() {
            this.listResult = []
            this.listGuides = []

            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            Moment.locale('pt-br')

            let dtIni = Moment(this.dateInitial)
            let dtFim = Moment(this.dateFinal)  

            let diff = dtFim.diff(dtIni, 'days')

            if (diff < 0) {
            this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
            valid = false
            } 

            if (!this.selectedCovenant) {
            this.$toasted.global.defaultError({msg: 'Informe o convênio.'})
            valid = false
            }

            if (this.listPatients.length == 0) {
            this.$toasted.global.defaultError({msg: 'Adicione os pacientes.'})
            valid = false
            }

            if (valid) {
                this.$refs.btnSave.disabled = true

                this.generating = true
                this.textBtnPrint = 'Aguarde...'

                this.jobResult = null

                let data = {
                    dateInitial: this.dateInitial,
                    dateFinal: this.dateFinal,
                    covenant: this.selectedCovenant._id,
                    patients: this.listPatients 
                }

                const url = `${baseApiUrl}/guidesANS/getGuidesANS/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.post(url, data).then(res => {
                    this.value = 0

                    this.jobID = res.data.id

                    this.getResultJob()
                })
            }
        },
        async getResultJob() {
            return new Promise((resolve, reject) => {
                try {
                    const urlJob = `${baseApiUrl}/jobs/${this.jobID}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    const startTime = Date.now();
                    const intervalTime = 7000; // Intervalo de 5 segundos
                    const endTime = startTime + 600000; // 10 minutos

                    // Usando setInterval para checar o job periodicamente
                    const intervalId = setInterval(async () => {
                        try {
                            const res = await axios.get(urlJob)
                            this.jobResult = res.data
                            this.value = this.jobResult.progress

                            if (this.jobResult.status > 0 || Date.now() >= endTime) {
                                this.value = 100
                                this.showGuides()
                                clearInterval(intervalId) // Para o intervalo quando o job terminar ou o tempo acabar
                                resolve();
                            }
                        } catch (error) {
                            showError(error)
                            clearInterval(intervalId) // Para o intervalo em caso de erro
                            reject(error)
                        }
                    }, intervalTime)
                } catch(e) {
                    reject()
                }
            })          
        },
        showGuides() {
            if (this.jobResult) {
                if (this.jobResult.status == 1) {
                    this.listResult = this.jobResult.data
                    
                    for (let lr of this.listResult) {
                        if (lr) {
                            if (lr.patient) {              
                                this.listGuides.push({
                                    patient: lr.patient.name,
                                    cpf: lr.patient.cpf,
                                    requester: lr.professionalRequester.name,
                                    attendances: lr.listDates,
                                    totalGlobal: lr.totalGlobal,
                                    indexGuide: lr.indexGuide
                                })
                            }
                        }
                    }

                    this.listTSErrors = []

                    this.listResult[0].listAttendancesGuides.find(elem => {
                        if (elem.valorUnitario === 0) {
                            this.listTSErrors.push(elem.name)
                        }
                    })

                    this.listResult = this.listResult.filter(function (i) {
                        return i
                    })

                    this.$root.$emit('bv::show::modal', "modalGuides", null)
                    
                    this.generating = false
                    this.textBtnPrint = 'GERAR'
                } else {
                    let listErrors = this.jobResult.data

                    this.generating = false
                    this.textBtnPrint = 'GERAR'  
                    
                    this.listErrorGuides = []

                    if (Array.isArray(listErrors)) {
                        let errors = listErrors.filter((elem, i) => listErrors.indexOf(elem) === i)

                        for (let e of errors) {
                            this.listErrorGuides.push({
                                error: e                                   
                            }) 
                        }

                        this.$root.$emit('bv::show::modal', "errorsGuides", null)
                    } else {
                        this.$toasted.global.defaultError({msg: listErrors})
                    }                        
                }
            } else {
                this.$toasted.global.defaultError({msg: 'Não foi possível carregar as informações.'})
            }

            this.$refs.btnSave.disabled = false
        },
        loadSettings() {
            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.settings = res.data                
            }).catch(showError)                      
        },          
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r,  text: r.fantasyname })                        
                }
            }).catch(showError)                        
        },
        detailGuide(itemSelected) {
            this.patientSelected = itemSelected.patient

            this.listDetails = []

            Moment.locale('pt-br')

            this.dataSelected = itemSelected

            for (let ld of itemSelected.attendances) {
                for (let att of ld.listAttendancesDates) {
                    this.listDetails.push({
                        cpf: itemSelected.cpf,
                        indexGuide: itemSelected.indexGuide,
                        patient: itemSelected.patient,
                        date: Moment(ld.date).format('L'),
                        attendance: att.name,
                        count: att.count,
                        startDone: att.startDone,
                        finishDone: att.finishDone
                    })
                }
            }

            for (let d of this.listDetails) {
                this.listTSErrors.find(elem => {
                    if (elem === d.attendance) {
                        d._cellVariants = {
                            'date': 'danger',
                            'attendance': 'danger',
                            'count': 'danger', 
                            'startDone': 'danger',    
                            'finishDone': 'danger',
                            'actions': 'danger'
                        }                            
                    }
                })
            }            

            this.listDetails.sort((a,b) => {
                if (a.attendance > b.attendance) {
                    return 1;
                }

                if (a.attendance < b.attendance) {
                    return -1;
                }
                
                return 0;
            })
            
            this.refreshTotalDetails()

            this.$root.$emit('bv::show::modal', "detailGuides", null)
        },
        deleteGuide(index) {
            this.$bvModal.msgBoxConfirm('Deseja remover esta guia?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {      
                    if (value) {
                        let newResult = [] 
                        let scales = []

                        for (let lr of this.listResult) {
                            if (!Array.isArray(lr)) {
                                if (lr.indexGuide != this.listGuides[index].indexGuide) {
                                    newResult.push(lr)
                                }
                            }
                        }
                        
                        for (let s of this.listResult[this.listResult.length - 1]) {
                            if (s.indexGuide != this.listGuides[index].indexGuide) {
                                scales.push(s)
                            }
                        }
                        
                        newResult.push(scales)
                        
                        this.listResult = newResult

                        this.listGuides.splice(index, 1)

                        this.refreshTotalDetails()
                    }
                })
        },
        refreshTotalDetails() {
            this.listTotalDetails = []

            let finded = false

            for (let d of this.listDetails) {
                finded = false

                for (let ltd of this.listTotalDetails) {
                    if (ltd.attendance === d.attendance) {
                        ltd.count += d.count
                        finded = true
                        break
                    }
                }

                if (!finded) {
                    this.listTotalDetails.push({
                        attendance: d.attendance,
                        count: d.count
                    })
                }
            }
        },
        deleteAttendance(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover este atendimento?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {      
                    if (value) {
                        Moment.locale('pt-br')

                        let newAttendances = []

                        let idGuideDeleted = 0

                        for (let lr of this.listResult) {
                            if (!Array.isArray(lr)) {
                                if (lr.indexGuide === itemSelected.indexGuide && lr.patient.cpf === itemSelected.cpf && lr.patient.name === itemSelected.patient) {
                                    for (let a of lr.listAttendancesGuides) {
                                        let dateAtt = (itemSelected.date).split('')
                                        if (a.name === itemSelected.attendance && 
                                            a.date === `${dateAtt[6]}${dateAtt[7]}${dateAtt[8]}${dateAtt[9]}-${dateAtt[3]}${dateAtt[4]}-${dateAtt[0]}${dateAtt[1]}` && 
                                            a.startDone === itemSelected.startDone) {
                                            a.count = 0 
                                            idGuideDeleted = a.idAttendanceGuide
                                        }
                                    }

                                    for (let d of lr.listDates) {
                                        newAttendances = []

                                        for (let a of d.listAttendancesDates) {
                                            if (a.name !== itemSelected.attendance || Moment(d.date).format('L') !== itemSelected.date || a.startDone !== itemSelected.startDone) {
                                                newAttendances.push(a)
                                            }
                                        }    
                                        
                                        d.listAttendancesDates = newAttendances
                                    }
                                }
                            } else {
                                for (let l of lr) {
                                    if (l.idAttendanceGuide === idGuideDeleted) {
                                        l.deleted = true
                                        break
                                    }
                                }
                            }
                        }

                        let newLisGuides = []

                        for (let g of this.listGuides) {
                            if (g.indexGuide === itemSelected.indexGuide && g.cpf === itemSelected.cpf && g.patient === itemSelected.patient) {
                                for (let d of g.attendances) {
                                    newLisGuides = []

                                    for (let a of d.listAttendancesDates) {
                                        if (a.name !== itemSelected.attendance || Moment(d.date).format('L') !== itemSelected.date || a.startDone !== itemSelected.startDone) {
                                            newLisGuides.push(a)
                                        }
                                    }

                                    d.listAttendancesDates = newLisGuides
                                }

                                break
                            }
                        }                        

                        let newListDetails = []

                        for (let ld of this.listDetails) {
                            if (ld.attendance !== itemSelected.attendance || ld.date !== itemSelected.date || ld.startDone !== itemSelected.startDone) {
                                newListDetails.push(ld)
                            }
                        }

                        this.listDetails = newListDetails

                        this.refreshTotalDetails()
                    }
                })
        },
        removeAllAttendances() {
            this.$bvModal.msgBoxConfirm('Deseja remover todos os atendimento?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
            }).then(value => {      
                if (value) {
                    Moment.locale('pt-br')

                    let newAttendances = []

                    for (let lr of this.listResult) {
                        if (!Array.isArray(lr)) {
                            for (let a of lr.listAttendancesGuides) {
                                a.count = 0 
                            }

                            for (let d of lr.listDates) {
                                newAttendances = []

                                for (let a of d.listAttendancesDates) {
                                    newAttendances.push(a)
                                }    
                                
                                d.listAttendancesDates = newAttendances
                            }
                        } else {
                            for (let l of lr) {
                                l.deleted = true
                            }
                        }
                    }

                    for (let g of this.listGuides) {
                        for (let d of g.attendances) {
                            d.listAttendancesDates = []
                        }
                    }                        

                    this.listDetails = []

                    this.refreshTotalDetails()
                }
            })
        },
        loadAdmissions() {
            try {
                this.isLoading = true

                const url = `${baseApiUrl}/patient/getPerCovenant/${this.selectedCovenant._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    let admissions = res.data   

                    this.listPatients = []
                    this.patientsAdmission = []
                    this.listResult = []
                    this.listDetails = []
                    this.listGuides = []
                    
                    for (let a of admissions) {
                        this.patientsAdmission.push(
                            {
                                _id: a._id,
                                name: a.name,
                                cpf: a.cpf,
                                rg: a.rg,
                                birthday: a.birthday,
                                covenant: a.covenant.name,
                                covenantId: a.covenant._id,
                                covenantNumber: a.covenant.number,
                                cellVariants: {
                                    name: '',
                                    cpf: '',
                                    covenant: ''
                                }
                            }
                        )
                    }     
                    
                    this.loadTypeServices()
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })          
            } catch(e) {
                this.isLoading = false
                console.log(e)
            }            
        },        
        setModal(modal) {
            if (this.selectedCovenant) {
                this.modal = modal

                this.patientsAdmissionLookup = []

                this.patientsAdmission.find(elem => {
                    if (elem.covenantId === this.selectedCovenant._id) {
                        this.patientsAdmissionLookup.push(elem)
                    }
                })
            }
        },
        addPatient(payload) {
            this.listPatients = []

            this.listPatients.push(payload.patientSelected)
        },
        deletePatient(index) {
            this.listPatients.splice(index, 1)            
        },
        addAttendance() {         
            Moment.locale('pt-br')                        

            for (let lr of this.listResult) {
                if (!Array.isArray(lr)) {
                    if (lr.indexGuide == this.dataSelected.indexGuide && lr.patient.cpf == this.dataSelected.cpf && lr.patient.name == this.dataSelected.patient) {
                        if (this.listAttendancesPatientSelected.length === 0) {
                            this.$toasted.global.defaultError({ msg: 'Nenhum atendimento selecionado.' })
                            return                            
                        }

                        for (let lap of this.listAttendancesPatientSelected) {
                            if (!lap.date) {
                                this.$toasted.global.defaultError({ msg: 'Data não informada.' })
                                return
                            }

                            if (!util.hourIsValide(lap.startDone)) {
                                this.$toasted.global.defaultError({ msg: 'Hora Inicial no formato inválido.' })
                                return
                            }

                            lap.startDone = lap.startDone + ':00'

                            if (!util.hourIsValide(lap.finishDone)) {
                                this.$toasted.global.defaultError({ msg: 'Hora Final no formato inválido.' })
                                return
                            }

                            lap.finishDone = lap.finishDone + ':00'                          

                            lr.listAttendancesGuides.push({
                                codigoProcedimento: lap.codeTA,
                                codigoTabela: lap.tableTypeTA,
                                count: lap.count,
                                date: lap.date,
                                finishDone: lap.finishDone,
                                name: lap.name,
                                startDone: lap.startDone,
                                total: Number(lap.valueTA),
                                valorUnitario: lap.valueTA
                            })

                            lr.listDates.push({
                                date: lap.date,                                
                                listAttendancesDates: [{
                                    count: lap.count,
                                    name: lap.name,
                                    startDone: lap.startDone,
                                    finishDone: lap.finishDone
                                }]
                            })
                        }

                        break
                    }
                }
            }

            for (let lap of this.listAttendancesPatientSelected) {
                this.listDetails.push({
                    attendance: lap.name,
                    count: lap.count,
                    cpf: this.dataSelected.cpf,
                    date: Moment(lap.date).format('L'),
                    finishDone: lap.finishDone,
                    indexGuide: this.dataSelected.indexGuide,
                    patient: this.dataSelected.patient,
                    startDone: lap.startDone
                })
            }

            this.listDetails.sort((a,b) => {
                if (a.attendance > b.attendance) {
                    return 1;
                }

                if (a.attendance < b.attendance) {
                    return -1;
                }
                
                return 0;
            })

            for (let rt of this.resultTypeServices) {
                rt.date = null
                rt.startDone = null
                rt.finishDone = null
            }

            this.refreshTotalDetails()

            this.$bvModal.hide('modal-addAttendance')
        },        
        onRowSelectedAttendances(listSelected) {
            this.listAttendancesPatientSelected = listSelected
        },
        loadTypeServices() {
            const url = `${baseApiUrl}/covenants/getInfoTypeServicePerName/${encodeURIComponent(this.selectedCovenant.fantasyname)}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then((res) => {
                this.resultTypeServices = []

                for (let d of res.data) {
                    d.count = 1
                    this.resultTypeServices.push(d)
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        }
      },
      created() {
        this.$_loadingTimeInterval = null
      },
      mounted() {
        this.theme = localStorage.getItem('theme')
  
        this.loadSettings()
        this.loadCovenants()          
      }
  }
</script>
  
<style>
  .card-green {
    background-color: rgb(196, 235, 220);
  }
  
  .card-wine {
    background-color: rgb(255, 241, 241);
  }
</style>
