<template>
  <Loading v-if="isLoading" />
  <div v-else class="scaleCalendar">
    <PageTitle icon="fa fa-calendar" main="Apontamentos" sub="Calendário" />
    <div class="home-page-tabs">
      <b-row>
        <b-col md="4" sm="12" v-if="user.admin">  
            <b-form-group label="Profissional:" label-for="professional">
                <b-row>
                    <b-col md="11" sm="12">
                        <b-input size="sm" disabled v-model="professionalSelected.name"></b-input>
                    </b-col>
                    <b-col md="1" sm="12">
                        <b-button size="sm" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'">
                            <i class="fa fa-search"></i>
                        </b-button>

                        <Lookup ref="Lookup" title="Profissionais" :list="professionals" :fields="pfields" :invalidFeedback="invalidFeedback" @event="getProfessional"
                            resultTable="professionalScale"></Lookup>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
            <b-form-group label="Data:" label-for="date">
                <b-row>
                    <b-col md="4" sm="12">
                        <b-input type="date" size="sm" v-model="dateSelected" @change="getProfessionalsDay()"></b-input>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="patients.length > 0">        
        <b-button size="sm" class="mt-3 ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Atendimentos'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>
      </b-row>
      <b-row>
        <b-col>
            <b-card :class="`card-${theme} mt-3`" header="Pacientes" header-bg-variant="dark" header-text-variant="white">
                <b-table ref="selectableTablePatients" head-variant="light" outlined hover :items="patients" 
                        :fields="fields" responsive fixed>
                        <template #cell(actions)="row">
                            <b-button v-show="!row.item.released" class="mr-2" size="sm" variant="secondary" @click="setRelease(row.item)" v-b-popover.hover.top="'Alta'">
                                <i class="fa fa-sign-out"></i>
                            </b-button>
                            <b-button v-show="!row.item.finishDone && !row.item.released && !row.item.cancellation" class="mr-2" size="sm" variant="danger" @click="lookupEvent(row.item)" v-b-popover.hover.top="'Ocorrências'">
                                <i class="fa fa-free-code-camp"></i>
                            </b-button>
                            <b-button v-show="!row.item.finishDone && !row.item.released && !row.item.cancellation" size="sm" @click="lookupNotation(row.item, row.index, $event.target)" 
                                      class="mr-1" variant="primary" v-b-popover.hover.top="'Apontamento'">
                                <i class="fa fa-clock-o"></i>
                            </b-button>                      
                            <b-button v-show="row.item.finishDone && !row.item.released && !row.item.cancellation" size="sm" variant="success" @click="lookupEvolution(row.item, row.index, $event.target)"  
                                      v-b-popover.hover.top="'Evolução'" class="mr-2">
                                <i class="fa fa-file-text-o"></i>
                            </b-button>
                            <b-button v-show="row.item.evolution && !row.item.released && !row.item.cancellation" size="sm" variant="secondary" @click="lookupAnamnesis(row.item, row.index, $event.target)"  
                                      v-b-popover.hover.top="'Laudo'" class="mr-2">
                                <i class="fa fa-file-text-o"></i>
                            </b-button>
                            <b-button v-show="row.item.anamnesis && !row.item.released" size="sm" variant="warning" @click="lookupResume(row.item, row.index, $event.target)" 
                                      v-b-popover.hover.top="'Resumo'" class="mr-2">
                                <i class="fa fa-file-text-o"></i>
                            </b-button>                            
                        </template>
                </b-table>      

                <div v-if="patients.length == 0" class="d-flex justify-content-center mb-3">    
                    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
                </div>                  

                <!-- Apontamento -->
                <b-modal :id="infoModalNotation.id" :title="infoModalNotation.title" @hidden="resetModal" @ok="handleNotationOk" size="lg">
                    <b-form-group label="Hora" label-for="hour-input" invalid-feedback="Informe a hora">
                        <b-input size="sm" v-model="hour" v-mask="'##:##'" placeholder="HH:MM" required></b-input>
                    </b-form-group>
                    <b-table v-if="!attendanceSelected.startDone" ref="selectableTablePatients" head-variant="light" outlined hover :items="specialities" 
                        :fields="fieldsSpecialities" responsive>
                        <template #cell(tsCovenant)="row">
                            <b-form-select :id="row.item.name" size="sm" v-model="row.item.typeServiceCovenant" @change="changeTypeService(row.item)" :options="typesServiceCovenant"></b-form-select>
                        </template>
                    </b-table>
                </b-modal>

                <!-- Ocorrências -->
                <b-modal :id="infoModalEvent.id" :title="infoModalEvent.title" @hidden="resetModal" @ok="handleEventOk" size="sm">
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                        <b-form-checkbox-group v-model="eventsSelecteds" :options="events" class="mb-3"
                            value-field="item" text-field="name" disabled-field="notEnabled" >
                        </b-form-checkbox-group>              
                        <br>
                        <b-table ref="events" head-variant="light" outlined hover :items="eventsPatient" :fields="efields" responsive="sm" fixed>

                        </b-table>                                 
                    </form>
                </b-modal>       

                <!-- Alerta -->
                <b-modal :id="infoModalAlert.id" :title="infoModalAlert.title" @hidden="resetModal" @ok="handleAlertOk" size="lg">
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                        <b-form-group label="Alerta" label-for="alert-input" invalid-feedback="Informe os dados do Alerta">
                            <b-form-textarea id="textarea" v-model="alert" rows="1" max-rows="10"></b-form-textarea>                                                      
                        </b-form-group>
                    </form>
                </b-modal>

                <!-- Evolução -->    
                <b-modal :id="infoModalEvolution.id" :title="infoModalEvolution.title" @hidden="resetModal" @ok="handleEvolutionOk" size="lg">
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                        <b-form-group label="Evolução" label-for="evolution-input" invalid-feedback="Informe os dados da evolução">
                            <b-form-textarea id="textarea" v-model="evolution" rows="1" max-rows="10"></b-form-textarea>                                                      
                        </b-form-group>
                        <b-card :class="`card-${theme} mt-3`" header="Histórico" header-bg-variant="dark" header-text-variant="white">
                            <b-table ref="selectableTableEvolutions" head-variant="light" outlined hover :items="evolutions" 
                                :fields="fieldsEv" responsive="sm" fixed>
                                <template #cell(actions)="row">
                                    <b-button size="sm" @click="lookupResumeEvolution(row.item, $event.target)" v-b-popover.hover.top="'Visualizar'">
                                        <i class="fa fa-eye"></i>
                                    </b-button>                                    
                                </template>                        
                            </b-table>
                        </b-card>
                        
                    </form> <br>
                    <b-button size="sm" class="btn-warning" @click="lookupResumeDocuments($event.target)" v-b-popover.hover.top="'Documentos'">
                        Documentos
                    </b-button>
                </b-modal>   
                
                <!-- Documentos -->    
                <b-modal :id="infoModalDocuments.id" :title="infoModalDocuments.title" @hidden="resetModal" @ok="handleDocumentsOk" size="lg">
                        <b-card :class="`card-${theme} mt-3`" header="Documentos" header-bg-variant="dark" header-text-variant="white">
                            <b-table ref="selectableTableDocuments" head-variant="light" outlined hover :items="patientSelected.documents" 
                                :fields="fieldsDc" responsive="sm" fixed>
                                <template #cell(actions)="row">
                                    <b-button size="sm" @click="openFile(row.item, $event.target)" v-b-popover.hover.top="'Visualizar'">
                                        <i class="fa fa-eye"></i>
                                    </b-button>                                    
                                </template>                        
                            </b-table>
                        </b-card>
                </b-modal>

                <!-- Laudo  -->
                <b-modal :id="infoModalAnamnesis.id" :title="infoModalAnamnesis.title" @hidden="resetModal" @ok="handleAnamnesisOk" size="lg">
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                        <b-form-group label="Laudo" label-for="anamnesis-input" invalid-feedback="Informe os dados do laudo">
                            <b-form-textarea id="textarea" v-model="anamnesis" rows="1" max-rows="10"></b-form-textarea>                                                      
                        </b-form-group>
                    </form>
                </b-modal>                   

                <!-- Resumo  -->
                <b-modal :id="infoModalResume.id" :title="infoModalResume.title" @hidden="resetModal" @ok="handleResumeOk" size="lg" ok-only>
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                        <b-form-group :label="`Paciente: ${patientSelected.name}`" label-for="resume-input"></b-form-group>
                        <b-form-group :label="`CPF: ${patientSelected.cpf}`" label-for="resume-input"></b-form-group>
                        <b-form-group :label="`Convênio: ${patientSelected.covenant}`" label-for="resume-input"></b-form-group>
                        <hr>
                        <b-form-group :label="`Início Previsto: ${patientSelected.start}`" label-for="resume-input"></b-form-group>
                        <b-form-group :label="`Início Realizado: ${patientSelected.startDone}`" label-for="resume-input"></b-form-group>
                        <b-form-group :label="`Término Previsto: ${patientSelected.finish}`" label-for="resume-input"></b-form-group>
                        <b-form-group :label="`Término Realizado: ${patientSelected.finishDone}`" label-for="resume-input"></b-form-group>
                        <hr>
                        <b-form-group label="Evolução" label-for="resume-input">
                            <b-form-textarea id="textarea" v-model="patientSelected.evolution" rows="1" max-rows="10"></b-form-textarea> 
                        </b-form-group>
                        <hr>
                        <b-form-group label="Laudo" label-for="resume-input">
                            <b-form-textarea id="textarea" v-model="patientSelected.anamnesis" rows="1" max-rows="10"></b-form-textarea> 
                        </b-form-group>
                    </form>
                </b-modal>  
                
                <b-modal :id="infoModalEvolutionView.id" :title="infoModalEvolutionView.title" @hidden="resetModal" @ok="handleEvolutionViewOk" 
                         size="lg" ok-only>
                    <form ref="form" @submit.stop.prevent="handleSubmitEvolutionView">
                        <b-form-group :label="`Paciente: ${patientSelected.name}`" label-for="resume-input"></b-form-group>
                        <b-form-group :label="`CPF: ${patientSelected.cpf}`" label-for="resume-input"></b-form-group>
                        <hr>
                        <b-form-group label="Descrição" label-for="resume-input">
                            <b-form-textarea id="textarea" v-model="evolutionSelected" rows="1" max-rows="10"></b-form-textarea> 
                        </b-form-group>
                    </form>
                </b-modal>                
            </b-card>
        </b-col>
      </b-row>
    </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import { mapState } from "vuex"
import Lookup from '../default/Lookup'
import Moment from 'moment'
import Loading from "../template/Loading"
import util from '../default/util'

export default {
    name: "Notation",
    components: { PageTitle, Lookup, Loading },
    data: function() {
        return {
            theme: null,
            isLoading: false,
            attendanceSelected: {},
            generatingPDF: false,
            textBtnPrint: 'IMPRIMIR',
            scale: {},
            dateSelected: null,
            invalidFeedback: "Selecione um registro",
            previousSelecteds: [],
            patients: [],
            patient: {},
            patientSelected: {},
            professionals: [],
            professionalSelected: {},
            evolution: null,
            alert: null,
            anamnesis: null,
            settings: null,
            specialitiesSelecteds: [],
            specialities: [],
            eventsSelecteds: [],
            events: [],
            eventsPatient: [],
            typeServicesSecondary: [],
            typeServicesSecondarySelected: [],
            typeServiceOld: null,
            allTypesService: [],
            evolutions: [],
            fields: [                 
                { key: 'name', label: 'Nome', sortable: false, },
                { key: 'cpf', label: 'CPF', sortable: false },            
                { key: 'accommodationFull', label: 'Local de Atendimento', sortable: false },
                { key: 'attendancesFull', label: 'Tipos de Atendimento', sortable: false, },
                { key: 'covenantName', label: 'Convênio', sortable: false, },     
                { key: 'actions', label: 'Ações' }        
            ],       
            pfields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },
                { key: 'advice.number', label: 'Registro', sortable: true },         
            ],
            efields: [
                { key: 'hour', label: 'Hora', sortable: true },
                { key: 'name', label: 'Ocorrência', sortable: true, },
            ],    
            fieldsEv: [                 
                { key: 'date', label: 'Data', sortable: true },
                { key: 'professional.name', label: 'Profissional', sortable: false },
                { key: 'professional.adviceNumber', label: 'Registro', sortable: false },
                { key: 'actions', label: 'Ações' }
            ],
            fieldsDc: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'actions', label: 'Ações' }
            ],   
            infoModalEvolutionView: {
                id: 'info-modal-evolution-view',
                title: '',
                content: ''
            },
            fieldsSpecialities: [
                { key: 'name', label: 'Atendimento', sortable: false },
                { key: 'tsCovenant', label: 'Atendimento do Convênio', sortable: false }
            ],
            evolutionSelected: null,
            stateHour: null,
            hour: null,
            minutes: null,
            infoModalNotation: {
                id: 'info-modal-notation',
                title: '',
                content: ''
            },   
            infoModalEvent: {
                id: 'info-modal-event',
                title: '',
                content: ''
            },        
            infoModalAlert: {
                id: 'info-modal-alert',
                title: '',
                content: ''
            },                     
            infoModalEvolution: {
                id: 'info-modal-evolution',
                title: '',
                content: ''
            },        
            infoModalAnamnesis: {
                id: 'info-modal-anamnesis',
                title: '',
                content: ''
            },      
            infoModalResume: {
                id: 'info-modal-resume',
                title: '',
                content: ''
            },
            infoModalDocuments: {
                id: 'info-modal-documents',
                title: '',
                content: ''
            },
            admission: null                         
        }        
    },
    computed: mapState(["user"]),
    methods: {        
        async print() {
            this.generatingPDF = true                        

            let listTopics = []
            
            this.textBtnPrint = 'Aguarde...'                 
            listTopics.push(this.professionalSelected.name)

            await this.generatePDF(listTopics, this.patients) 
        },
        async generatePDF(listTopics, listPatients) {
            let contentPdf = {
                title: 'ATENDIMENTOS',
                format: 'A4',
                orientation: 'portrait',
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: [
                            {
                                original: 'name',
                                exibition: 'Nome'
                            },
                            {
                                original: 'cpf',
                                exibition: 'CPF'
                            },
                            {
                                original: 'accommodationFull',
                                exibition: 'Local de Atendimento'
                            },
                            {
                                original: 'attendancesFull',
                                exibition: 'Tipos Atend.'
                            },                                                      
                            {
                                original: 'start',
                                exibition: 'Início'
                            }
                        ],
                        list: listPatients
                    },
                ]
            }

            this.isLoading = true

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
                var file = new Blob([res.data], {type: 'application/pdf'})
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL)

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'
                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        async getProfessionalsDay() {        
            this.patients = []

            if (this.professionalSelected.name == undefined) {
                this.$toasted.global.defaultError({msg: 'Selecione o Profissional'}) 
            } else {
                try {
                    this.isLoading = true

                    const url = `${baseApiUrl}/scale/date/${this.dateSelected}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    axios.get(url).then(res => {
                        this.scale = res.data                               
                                                
                        if (!this.scale) {
                            this.scale = {
                                date: '',
                                professionals: []
                            }
                        } else {
                            for (let p of this.scale.professionals) {
                                if (p.cpf == this.professionalSelected.cpf) {                                       
                                    for (let pa of p.attendance[0].patients) {
                                        if (pa.name) {
                                            let splBegin = []
                                            
                                            try {
                                                splBegin = pa.start.split(":")
                                            } catch(e) {
                                                pa.start = '00:00:00'
                                                splBegin = pa.start.split(":")
                                            }
                                            
                                            let beginMin = (Number(splBegin[0] * 60)) + Number(splBegin[1])

                                            pa.startCompare = beginMin

                                            if (pa.cancellation) {
                                                pa._cellVariants = {
                                                    'name': pa.cancellation ? 'danger' : '',
                                                    'cpf': pa.cancellation ? 'danger' : '',
                                                    'accommodationFull': pa.cancellation ? 'danger' : '', 
                                                    'attendancesFull': pa.cancellation ? 'danger' : '',    
                                                    start: pa.cancellation ? 'danger' : '',
                                                    finish: pa.cancellation ? 'danger' : '',
                                                    actions: pa.cancellation ? 'danger' : ''
                                                }
                                            }
                                        }
                                    }

                                    this.patients = p.attendance[0].patients                                   
                                    
                                    for (let pa of this.patients) {
                                        if (pa.name) {
                                            try {
                                                pa.accommodationFull = `${pa.accommodation.building} -> ${pa.accommodation.floor} -> ${pa.accommodation.apartment} -> ${pa.accommodation.name}`
                                            } catch(e) {
                                                if (!this.settings[0].params[13].value) {
                                                    this.$toasted.global.defaultError({msg: 'Local de atendimento do profissional não informado.'})
                                                }
                                            }        
                                            
                                            if (pa.attendances) {
                                                pa.attendancesFull = ''

                                                for (let a of pa.attendances) {
                                                    if (pa.attendancesFull == '') {
                                                        pa.attendancesFull = a.name
                                                    } else {
                                                        pa.attendancesFull = pa.attendancesFull + ' - ' + a.name
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    
                                    break
                                }                                                                       
                            }       
                        }        
                        
                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })                
                } catch(e) {
                    console.log(e)
                }                 
            }            
        },
        selectPatient(itemSelected) {
            this.patientSelected = {
                _id: itemSelected._id,
                name: itemSelected.name,
                cpf: itemSelected.cpf,
                covenant: itemSelected.covenantName,
                accommodationFull: itemSelected.accommodationFull, 
                start: itemSelected.start,
                finish: itemSelected.finish,
                startDone: itemSelected.startDone,
                finishDone: itemSelected.finishDone,
                evolution: itemSelected.evolution,
                anamnesis: itemSelected.anamnesis,
                questions: itemSelected.questions,
                admissionAttendanceNumber: itemSelected.admissionAttendanceNumber,
                attendances: itemSelected.attendances,
                documents: itemSelected.documents
            }

            this.eventsPatient = []

            if (itemSelected.events) {
                for (let le of itemSelected.events) {
                    for (let e of le) {
                        this.eventsPatient.push(e)
                    }
                }
            }

            this.evolution = this.patientSelected.evolution
            this.anamnesis = this.patientSelected.anamnesis

            this.specialities = []
            this.specialitiesSelecteds = []

            for (let a of itemSelected.attendances) {
                this.specialities.push({
                    item: {
                        typeService: a.name,
                        covenant: itemSelected.covenantName 
                    },
                    name: a.name                    
                })
            }

            this.typesServiceCovenant = this.allTypesService.filter(item => item.value.covenantId === itemSelected.covenantId)
        },
        newNotation(hour, allSpecialities) {            
            let indexPatient = 0
            let indexProfessional = 0

            try {
                if (!util.hourIsValide(hour)) {
                    throw 'Hora no formato inválido.'
                }

                hour = hour + ':00'

                let i = 0

                for (let p of this.scale.professionals) {
                    if (p.cpf == this.professionalSelected.cpf) {
                        indexProfessional = i    
                        break              
                    }

                    i += 1
                }
                
                for (let pa of this.scale.professionals[indexProfessional].attendance[0].patients) {
                    if (!pa.cancellation) {
                        if (this.patientSelected.cpf && this.patientSelected.cpf != '') {
                            if (pa.name == this.patientSelected.name && pa.cpf == this.patientSelected.cpf && pa.start == this.patientSelected.start && 
                                pa.finish == this.patientSelected.finish && (!pa.startDone || !pa.finishDone)) {
                                break
                            }
                        } else {
                            if (pa.name == this.patientSelected.name && pa.admissionAttendanceNumber == this.patientSelected.admissionAttendanceNumber && 
                                pa.start == this.patientSelected.start && pa.finish == this.patientSelected.finish && (!pa.startDone || !pa.finishDone)) {
                                break
                            }
                        }
                    }

                    indexPatient += 1
                }
                
                let listIndexAttendances = []
                let indexAttendance = 0
                
                if (!allSpecialities) {
                    for (let a of this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].attendances) {
                        if (this.attendanceSelected.startDone) {
                            listIndexAttendances.push(indexAttendance)
                        } else {
                            for (let s of this.specialitiesSelecteds) {             
                                if (s.typeServiceOld == a.name) {
                                    a.name = s.typeService
                                    listIndexAttendances.push(indexAttendance)
                                }
                            }
                        }

                        indexAttendance += 1
                    }
                } else {
                    for (let a of this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].attendances) {
                        a.name

                        listIndexAttendances.push(indexAttendance)

                        indexAttendance += 1
                    }
                }

                if (listIndexAttendances.length === 0 && !this.attendanceSelected.startDone) {
                    throw 'Nenhum atendimento selecionado.'
                }

                if (!this.patientSelected.startDone && listIndexAttendances.length == 0) {
                    this.$toasted.global.defaultError({msg: 'Nenhum tipo de atendimento informado'})
                } else {
                    if (!this.patientSelected.startDone) {
                        this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].startDone = hour
                    } else {
                        this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].finishDone = hour
                    }      

                    const method = 'post'

                    let data = {
                        scale: this.scale,
                        indexProfessional: indexProfessional,
                        indexPatient: indexPatient,
                        listIndexAttendances: listIndexAttendances,
                        secondaries: this.typeServicesSecondarySelected
                    }

                    this.typeServicesSecondarySelected = []
                    
                    this.isLoading = true
                        
                    axios[method](`${baseApiUrl}/scale/newNotation/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                        this.$toasted.global.defaultSuccess()                          
                        this.getProfessionalsDay()
                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })
                }
            } catch(e) {
                this.$toasted.global.defaultError({msg: `Erro ao realizar apontamento. ${e}`})
            }
        },   
        lookupNotation(item, index, button) {
            this.infoModalNotation.title = 'Informe o horário do atendimento'
            this.infoModalNotation.content = JSON.stringify(item, null)

            this.previousSelecteds = []

            this.attendanceSelected = item

            this.selectPatient(item)

            let hasQuestion = false

            if (this.patientSelected.questions != undefined) {
                if (this.patientSelected.questions.length > 0) {
                    for (let q of this.patientSelected.questions) {
                        if (q.status == 0 && q.type == 1) {
                            hasQuestion = true
                            break
                        }
                    }                    

                    if (hasQuestion) {
                        this.$bvModal.msgBoxConfirm('Deseja realizar a avaliação do paciente?', {
                            title: 'Confirmação',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'Sim',
                            cancelTitle: 'Não',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        }).then(value => {            
                            if (value) {
                                axios.get(`${baseApiUrl}/patient/getById/${this.patientSelected._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`)
                                .then((res) => {
                                    this.isLoading = false
                                    this.$router.push({ name: 'patientResume', params: { patient: res.data } })
                                }).catch(e => {
                                    this.isLoading = false
                                    this.$toasted.global.defaultError({ msg: e.response.data })
                                })                                
                            }
                        })
                    }
                }
            }             
            
            if (!hasQuestion) {
                try {
                    if (this.settings[0].params[13].value) {
                        let nameUrl = this.patientSelected.attendances[0].name.replaceAll('/', '---')

                        axios.get(`${baseApiUrl}/typeservice/getByName/${nameUrl}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`)
                        .then((res) => {                        
                            Moment.locale('pt-br')

                            let hourNotation = Moment(new Date(), 'hh:mm:ss').format('LTS') 

                            if (res.data.secondaries) {
                                if (res.data.secondaries.length == 0) {
                                    this.newNotation(hourNotation, 'N', true)   
                                } else {
                                    this.typeServicesSecondary = []

                                    for (let s of res.data.secondaries) {
                                        this.typeServicesSecondary.push(s.name)
                                    }

                                    let hms = hourNotation.split(':')

                                    this.hour = hms[0]
                                    this.minutes = hms[1]                                    

                                    this.$root.$emit('bv::show::modal', this.infoModalNotation.id, button)
                                }
                            } else {
                                this.newNotation(hourNotation, 'N', true)                                
                            }

                            this.isLoading = false
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })
                    } else {
                        this.$root.$emit('bv::show::modal', this.infoModalNotation.id, button)
                    }
                } catch(e) {
                    this.$root.$emit('bv::show::modal', this.infoModalNotation.id, button)
                }
                
            }            
        },
        lookupEvent(item, index, button) {
            this.previousSelecteds = []
            
            this.infoModalEvent.title = 'Informe as ocorrências'
            this.infoModalEvent.content = JSON.stringify(item, null,)

            this.selectPatient(item)
            
            this.$root.$emit('bv::show::modal', this.infoModalEvent.id, button)
        },        
        lookupAlert(item, index, button) {
            this.infoModalAlert.title = 'Informações do alerta'
            this.infoModalAlert.content = JSON.stringify(item, null,)

            this.selectPatient(item)
            
            this.$root.$emit('bv::show::modal', this.infoModalAlert.id, button)
        },          
        lookupEvolution(item, index, button) {
            this.previousSelecteds = []

            this.infoModalEvolution.title = 'Informe os dados da evolução'
            this.infoModalEvolution.content = JSON.stringify(item, null)            

            this.getEvolutions(item)
            this.selectPatient(item)
            
            this.$root.$emit('bv::show::modal', this.infoModalEvolution.id, button)
        },        
        lookupAnamnesis(item, index, button) {
            this.previousSelecteds = []

            this.infoModalAnamnesis.title = 'Informe os dados do laudo'
            this.infoModalAnamnesis.content = JSON.stringify(item, null,)

            this.selectPatient(item)
            
            this.$root.$emit('bv::show::modal', this.infoModalAnamnesis.id, button)
        },   
        lookupResume(item, index, button) {
            this.infoModalResume.title = 'Resumo'
            this.infoModalResume.content = JSON.stringify(item, null,)

            this.selectPatient(item)
            
            this.$root.$emit('bv::show::modal', this.infoModalResume.id, button)
        },                   
        setEvolution() {
            let indexPatient = 0
            let indexProfessional = 0

            this.scale.professionals.find((item, i) => {
                if (item.cpf == this.professionalSelected.cpf) {
                    indexProfessional = i                  
                }
            })                

            this.scale.professionals[indexProfessional].attendance[0].patients.find((item, i) => {
                if (item._id == this.patientSelected._id && item.start == this.patientSelected.start && item.finish == this.patientSelected.finish) {
                    indexPatient = i
                }
            })                        
            
            let data = {
                scale: this.scale,
                content: this.evolution,
                indexProfessional: indexProfessional,
                indexPatient: indexPatient,
                type: 'E'                
            }            

            const method = 'post'

            axios[method](`${baseApiUrl}/scale/newEvolutionOrAnamnesis/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
                .then(() => {
                    this.$toasted.global.defaultSuccess({msg: 'Evolução realizada com sucesso'})                          
                    this.getProfessionalsDay()
                    this.isLoading = false
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })
        }, 
        setAnamnesis() {
            let indexPatient = 0
            let indexProfessional = 0

            this.scale.professionals.find((item, i) => {
                if (item.cpf == this.professionalSelected.cpf) {
                    indexProfessional = i                  
                }
            })    

            this.scale.professionals[indexProfessional].attendance[0].patients.find((item, i) => {
                if (item.cpf == this.patientSelected.cpf && item.start == this.patientSelected.start && item.finish == this.patientSelected.finish) {
                    indexPatient = i
                }
            })                 
                        
            let data = {
                scale: this.scale,
                content: this.anamnesis,
                indexProfessional: indexProfessional,
                indexPatient: indexPatient,
                type: 'A'                
            }            

            const method = 'post'

            this.isLoading = true

            axios[method](`${baseApiUrl}/scale/newEvolutionOrAnamnesis/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                this.$toasted.global.defaultSuccess()
                this.getProfessionalsDay()
                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },       
        setRelease(itemSelected) {
            this.previousSelecteds = []

            this.$bvModal.msgBoxConfirm('Deseja efetuar a alta do paciente?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    const method = 'put'
                    const id = itemSelected.admissionCode ? `/${itemSelected.admissionCode}` : ''     
                    
                    this.isLoading = true

                    axios[method](`${baseApiUrl}/scale/setReleased${id}/${this.professionalSelected.cpf}/${itemSelected._id}/
                        ${itemSelected.start}/${itemSelected.finish}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.scale)
                    .then(() => {
                        this.$toasted.global.defaultSuccess({msg: 'Alta realizada com sucesso'})
                        this.getProfessionalsDay()
                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })                    
                }
            })
        },
        setAlert() {

        },
        setEvents() {
            let indexPatient = 0
            let indexProfessional = 0

            this.scale.professionals.find((item, i) => {
                if (item.cpf == this.professionalSelected.cpf) {
                    indexProfessional = i                  
                }
            })                

            this.scale.professionals[indexProfessional].attendance[0].patients.find((item, i) => {
                if (item.cpf == this.patientSelected.cpf && item.start == this.patientSelected.start && item.finish == this.patientSelected.finish) {
                    indexPatient = i
                }
            })          
            
            if (this.eventsSelecteds.length == 0) {
                this.$toasted.global.defaultError({ msg: 'Nenhuma ocorrência selecionada' })
            } else {
                for (let e of this.eventsSelecteds) {
                    if (this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].events == undefined) {
                        this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].events = []
                    }

                    Moment.locale('pt-br')

                    e.hour = Moment(new Date()).format('LTS')

                    this.scale.professionals[indexProfessional].attendance[0].patients[indexPatient].events.push({name: e}) 
                }

                let data = {
                    scale: this.scale,
                    content: this.eventsSelecteds,
                    indexProfessional: indexProfessional,
                    indexPatient: indexPatient,            
                }                
                
                const method = 'post'
                
                this.isLoading = true
                    
                axios[method](`${baseApiUrl}/scale/newEvent/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.getProfessionalsDay()
                    this.isLoading = false
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })
            }                      
        },
        resetModal() {
            this.stateHour = null
            this.hour = null
            this.minutes = null
            this.evolution = null
            this.anamnesis = null
            this.eventsSelecteds = []
            this.evolutionSelected = null
        },      
        handleNotationOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Notation')
        }, 
        handleEventOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Event')
        },  
        handleAlertOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Alert')            
        },       
        handleEvolutionOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Evolution')
        },  
        handleAnamnesisOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Anamnesis')
        },        
        handleResumeOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Resume')
        },   
        handleDocumentsOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Documents')
        },
        handleEvolutionViewOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            this.$nextTick(() => {
                this.$bvModal.hide(this.infoModalEvolutionView.id)
            })            
        },                        
        checkFormValidity(type) {
            if (type == 'Notation') {
                if (this.hour == null) {
                    return false
                } else {
                    return true
                }
            } else if (type == 'Evolution') {
                if (this.evolution == null) {
                    return false
                } else {
                    return true
                }
            } else if (type == 'Anamnesis') {
                if (this.anamnesis == null) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },     
        handleSubmitEvolutionView() {
            this.$nextTick(() => {
                this.$bvModal.hide(this.infoModalEvolutionView.id)
            })
        }, 
        handleSubmit(type) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity(type)) {
                return 
            }

            if (type == 'Notation')  {
                this.newNotation(this.hour, false)

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalNotation.id)
                })
            } else if (type == 'Event') {
                this.setEvents()

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalEvent.id)
                })     
            } else if (type == 'Alert') {
                this.setAlert()

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalAlert.id)
                })                                               
            } else if (type == 'Evolution') {
                this.setEvolution()

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalEvolution.id)
                })
            } else if (type == 'Anamnesis') {
                this.setAnamnesis()

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalAnamnesis.id)
                })
            } else if (type == 'Documents') {
                this.setEvents()

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalDocuments.id)
                })     
            } else {
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalResume.id)
                })
            }
        },    
        getProfessional(payload) {
            this.professionalSelected = payload.professionalSelected

            if (this.dateSelected) {
                this.getProfessionalsDay()
            }
        },
        loadEvents() {
            this.isLoading = true

            const url = `${baseApiUrl}/event/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                for (let d of res.data) {
                    if (d.type == 'Atendimento') {
                        this.events.push({
                            item: d,
                            name: d.name
                        })
                    }
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })    
        },
        loadTypeServicesCovenant() {
            this.isLoading = true

            const url = `${baseApiUrl}/covenants/getTypeServices/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.allTypesService = []

                for (let d of res.data) {
                    this.allTypesService.push({
                        text: d.typeService,
                        value: d
                    })
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        loadSettings() {
            this.isLoading = true

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.isLoading = false
                this.settings = res.data                
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        },
        getEvolutions(patientSelected) {
          let filter = ''

          if (patientSelected.cpf) {
            filter = patientSelected.cpf
          } else if (patientSelected.covenant) {
            filter = patientSelected.covenant.number
          } else {
            filter = patientSelected._id
          }

          const url = `${baseApiUrl}/scale/evolutions/${filter}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.get(url).then(res => {  
                let i = 0
                this.evolutions = []

                for (let e of res.data) {
                    this.evolutions.push(e)

                    i += 1

                    if (i == 5) {
                        break
                    }
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        selectEvolution(itemSelected) {
            this.evolutionSelected = itemSelected
        },        
        lookupResumeEvolution(item, button) {
            this.infoModalEvolutionView.title = 'Evolução'
            this.infoModalEvolutionView.content = JSON.stringify(item, null)

            this.selectEvolution(item.evolution)
            
            this.$root.$emit('bv::show::modal', this.infoModalEvolutionView.id, button)
        }, 
        lookupResumeDocuments(button) {
            this.infoModalDocuments.title = 'Documentos'
            
            this.$root.$emit('bv::show::modal', this.infoModalDocuments.id, button)
        }, 
        openFile(itemSelected) {
            try {
              let extension = ''
               for (let dc of this.patientSelected.documents) {
                  if (dc.name == itemSelected.name) {

                    if (dc.file.includes('pdf')) {
                      extension = 'pdf'
                    } else if (dc.file.includes('jpeg')) {
                      extension = 'jpeg'
                    } else if (dc.file.includes('jpg')) {
                      extension = 'jpg'
                    } else if (dc.file.includes('png')) {
                      extension = 'png'
                    }
                  
                    util.imprimirBase64(dc.file, extension)

                    break
                  }
               } 
            } catch (e) {
                this.$toasted.global.defaultError({ msg: 'Erro ao abrir o arquivo' })
            }
        },
        handleCheckboxChange(itemSelected) {
            this.typesServiceCovenant = []

            const added = itemSelected.filter(item => !this.previousSelecteds.includes(item))

            if (added.length > 0) {
                this.typesServiceCovenant = this.allTypesService.filter(item => item.value.covenant === added[0].covenant)
                this.typeServiceOld = added[0].typeService
            }

            const removed = this.previousSelecteds.filter(item => !itemSelected.includes(item))

            if (removed.length > 0) {
                this.$bvModal.hide('modal-prevent-setAttendanceCovenant')
            }

            this.previousSelecteds = [...itemSelected]

            this.typeServiceCovenant = null
        },
        changeTypeService(itemSelected) {
            let finded = false

            for (let s of this.specialitiesSelecteds) {
                if (s.typeServiceOld === itemSelected.item.typeService) {
                    s.typeService = itemSelected.typeServiceCovenant.typeService
                    finded = true
                }
            }

            if (!finded) {
                this.specialitiesSelecteds.push({
                    typeServiceOld: itemSelected.item.typeService,
                    typeService: itemSelected.typeServiceCovenant.typeService
                })
            }
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadSettings()
        this.loadEvents()
        this.loadTypeServicesCovenant()

        if (!this.user.admin) { 
            this.professionalSelected = this.user
        } else {
            this.professionalSelected = {}
        }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>