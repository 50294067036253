<template>
    <Loading v-if="isLoading" />  
    <div v-else class="batchsANS">
      <PageTitle icon="fa fa-hashtag" main="ANS" sub="Lotes de Guias" />
          <div class="batchsANS-page-tabs">
              <b-row>
                  <b-button class="ml-3" size="sm" variant="success" @click="newBatch()">
                      GERAR LOTE
                  </b-button> 
              </b-row>
              <b-row>
                  <b-col md="12" sm="12" class="mt-3">
                      <b-card :class="`card-${theme}`" header="Guias" border-variant="dark" header-border-variant="white" header-bg-variant="dark" header-text-variant="white">
                          <b-row>
                              <b-col md="2" sm="12">
                                  <b-form-group label="Data Inicial:" label-for="bgans-date-initial">
                                      <b-form-input size="sm" id="bgans-date-initial" type="date" v-model="dateInitial" required />
                                  </b-form-group>
                              </b-col>
                              <b-col md="2" sm="12">
                                  <b-form-group label="Data Final:" label-for="bgans-date-final">
                                      <b-form-input size="sm" id="bgans-date-final" type="date" v-model="dateFinal" required />
                                  </b-form-group>
                              </b-col>
                              <b-col md="2" sm="12">
                                  <b-form-group label="Convênio:" label-for="bgans-covenant">
                                      <b-form-select size="sm" v-model="selectedCovenant" :options="covenants"></b-form-select>
                                  </b-form-group>    
                              </b-col>
                              <b-col md="2" sm="12">
                                  <b-form-group label="Número Lote:" label-for="bgans-numberBatch">
                                      <b-form-input size="sm" id="bgans-date-numberBatch" type="number" v-model="numberBatchFilter" />
                                  </b-form-group>    
                              </b-col>
                              <b-col md="2" sm="12">
                                  <b-button size="sm" variant="warning" class="mt-4" @click="searchBatchs">
                                      <div class="d-flex align-items-center">
                                          <b-spinner small v-if="generating" class="mr-2"></b-spinner>
                                          <span>{{textBtnPrint}}</span>
                                      </div>                                    
                                  </b-button>
                              </b-col>
                          </b-row>
                      </b-card>                                          
                      <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="batchs" :fields="fields" responsive="sm" id="my-table"
                              :per-page="perPage" :current-page="currentPage" striped fixed>
                          <template v-slot:cell(actions)="row">
                            <b-button v-if="!row.item.nullified" class="ml-2 mt-2" size="sm" variant="secondary" @click="generateXml(row.item)" v-b-popover.hover.top="'Gerar Arquivo XML'">
                                <i class="fa fa-code"></i>
                            </b-button>

                            <b-button v-if="!row.item.nullified" class="ml-2 mt-2" size="sm" variant="info" @click="reportXml(row.item)" v-b-popover.hover.top="'Folha de Capa'">
                                <i class="fa fa-bar-chart"></i>
                            </b-button>

                            <b-button v-if="!row.item.nullified" class="ml-2 mt-2" size="sm" variant="danger" @click="deleteBatch(row.item)" v-b-popover.hover.top="'Excluir'">
                                <i class="fa fa-trash"></i>
                            </b-button>                             
                          </template>
                      </b-table>

                      <b-modal id="guides" title='Guias' size="lg" @ok="save">
                        <b-button v-b-modal.modal-getGuides size="sm" variant="success" v-b-popover.hover.top="'Adicionar Guia'">
                            <i class="fa fa-plus"></i>
                        </b-button>
                        <b-table class="mt-2" ref="detailGuides" head-variant="light" outlined hover :items="guidesSelected"
                            :fields="fieldsGuides" responsive="md" striped>
                            <template v-slot:cell(actions)="row">
                                <b-button class="ml-2" size="sm" variant="danger" @click="deleteGuide(row.index)" v-b-popover.hover.top="'Remover'">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                            </template>
                        </b-table>
                      </b-modal>

                      <b-modal id="modal-getGuides" title='Consulta de Guias' size="lg" ok-only>
                        <b-row>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Inicial:" label-for="bgans-date-initial">
                                    <b-form-input size="sm" id="bgans-date-initial" type="date" v-model="dateInitialGuide" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Final:" label-for="bgans-date-final">
                                    <b-form-input size="sm" id="bgans-date-final" type="date" v-model="dateFinalGuide" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Convênio:" label-for="bgans-covenant">
                                    <b-form-select size="sm" v-model="selectedCovenantGuide" :options="covenants"></b-form-select>
                                </b-form-group>    
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Número Guia:" label-for="bgans-numberGuide">
                                    <b-form-input size="sm" id="bgans-date-numberGuide" type="number" v-model="numberGuideFilter" />
                                </b-form-group>    
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-button size="sm" variant="warning" class="mt-4" @click="searchBatchGuides">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generating" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="guidesFilter" :fields="fieldsFilterGuides" responsive="sm" id="tableGuides"
                                    :per-page="perPageGuide" :current-page="currentPageGuide" fixed selectable select-mode="multi" @row-selected="onRowSelectedGuides">
                            </b-table>

                            <b-row>
                                <b-pagination size="sm" class="ml-3 mt-2 page-item" pills v-model="currentPageGuide" :total-rows="rowsGuide" :per-page="perPageGuide" aria-controls="tableGuides">
                                </b-pagination>
                            </b-row>
                        </b-row>
                      </b-modal>

                      <b-modal id="guidesDelete" title='Seleção de Guias' size="lg" @ok="sendDeleteGuidesBatch">
                        <b-table class="mt-2" ref="detailGuides" head-variant="light" outlined hover :items="guidesDeleteSelected"
                            :fields="fieldsGuidesDelete" responsive="md" striped>
                            <template v-slot:cell(actions)="row">
                                <b-button class="ml-2" size="sm" variant="danger" @click="deleteGuidesBatch(row.index)" v-b-popover.hover.top="'Remover'">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                            </template>
                        </b-table>
                      </b-modal>
                       
                      <b-row>
                          <b-pagination size="sm" class="ml-3 mt-2 page-item" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table">
                          </b-pagination>
                          <h5 class="badge2"><b-badge class="mt-2 ml-3" pill variant="danger">Cancelados</b-badge></h5>
                      </b-row>
                  </b-col>
              </b-row>
          </div>
    </div>
  </template>
  
  <script>
  import PageTitle from "../template/PageTitle"
  import { baseApiUrl, showError, userKey } from "@/global"
  import axios from "axios"
  import Moment from "moment"
  import Loading from "../template/Loading"
  
  export default {
      name: "batchsANS",
      components: { PageTitle, Loading },    
      data: function() {
          return {
              isLoading: false,
              theme: null,        
              dateInitial: null,
              dateFinal: null,
              dateInitialGuide: null,
              dateFinalGuide: null,
              loading: false,
              settings: null,
              selectedCovenant: null,
              selectedCovenantGuide: null,
              numberBatchFilter: null,
              numberGuideFilter: null,
              idBatchDeleted: null,
              valueTotalDeleted: null,
              covenants: [],      
              batchs: [],
              guidesFilter: [],
              guidesSelected: [],
              guidesDeleteSelected: [],
              guidesANS: null,
              originModal: '',
              fields: [                 
                { key: 'numberBatch', label: 'Número do Lote', sortable: true },
                { key: 'covenant.fantasyname', label: 'Convênio', sortable: true },
                { key: 'numberTotalGuides', label: 'Quantidade Guias', sortable: true },                
                { key: 'valueTotal2', label: 'Total (R$)', sortable: true },
                { key: 'actions', label: 'Ações' }
              ],
              fieldsGuides: [
                { key: 'patientName', label: 'Paciente', sortable: false },
                { key: 'covenant.fantasyname', label: 'Convênio', sortable: false },
                { key: 'numberGuide', label: 'Número da Guia', sortable: false },
                { key: 'actions', label: 'Ações' }
              ],
              fieldsGuidesDelete: [
                { key: 'nomeBeneficiario', label: 'Paciente', sortable: false },
                { key: 'numeroGuiaPrestador', label: 'Número da Guia', sortable: false },
                { key: 'actions', label: 'Ações' }
              ],              
              fieldsFilterGuides: [
                { key: 'patientName', label: 'Paciente', sortable: false },
                { key: 'covenant.fantasyname', label: 'Convênio', sortable: false },
                { key: 'numberGuide', label: 'Número da Guia', sortable: false },
              ],              
              generating: false,  
              textBtnPrint: 'Filtrar',
              currentPage: 1,
              currentPageGuide: 1,
              perPage: 10,
              perPageGuide: 10,
              infoModal: {
                  id: 'info-modal',
                  title: '',
                  content: ''
              },   
              selectMode: 'multi',
              job: null,
              jobID: '',
              jobResult: null,
              value: 0
          }
      },
      computed: {    
          rows() {
            return this.batchs.length
          },
          rowsGuide() {
            return this.guidesFilter.length
          }     
      },    
      methods: {
        save() {
            this.$bvModal.msgBoxConfirm('Deseja salvar o lote de guias?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {      
                if (value) {
                    let data = {
                        guides: this.guidesSelected,
                        covenant: this.selectedCovenantGuide
                    }

                    this.isLoading = true

                    const url = `${baseApiUrl}/batch/save/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    axios.post(url, data).then(res => {
                        this.value = 0

                        this.jobID = res.data.id

                        this.getResultJob()
                    }).catch(() => {
                        this.$toasted.global.defaultError({msg: 'Erro ao gerar lote de guias.'})
                        this.isLoading = false
                        this.guidesFilter = []
                        this.guidesSelected = []
                    })
                }
            })
        },
        async getResultJob() {
            return new Promise((resolve, reject) => {
                try {
                    const urlJob = `${baseApiUrl}/jobs/${this.jobID}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    const startTime = Date.now();
                    const intervalTime = 7000; // Intervalo de 5 segundos
                    const endTime = startTime + 600000; // 10 minutos

                    // Usando setInterval para checar o job periodicamente
                    const intervalId = setInterval(async () => {
                        try {
                            const res = await axios.get(urlJob)
                            this.jobResult = res.data
                            this.value = this.jobResult.progress

                            if (this.jobResult.status > 0 || Date.now() >= endTime) {
                                this.value = 100
                                this.showBatches()
                                clearInterval(intervalId) // Para o intervalo quando o job terminar ou o tempo acabar
                                resolve();
                            }
                        } catch (error) {
                            showError(error)
                            clearInterval(intervalId) // Para o intervalo em caso de erro
                            reject(error)
                        }
                    }, intervalTime)
                } catch(e) {
                    reject()
                }
            })          
        },
        showBatches() {
            if (this.jobResult) {
                this.isLoading = false
                this.$toasted.global.defaultSuccess({msg: 'Lote gerado com sucesso.'})
            } else {
                this.isLoading = false
                this.$toasted.global.defaultError({msg: 'Não foi possível gerar o lote.'})
            }
        },
        searchBatchs() {
            let valid = true

            if (this.dateInitial == '' || this.dateInitial == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data Inicial.'})
                valid = false
            }

            if (this.dateFinal == '' || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data Final.'})
                valid = false
            }

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitial)
            let dtFinal = Moment(this.dateFinal)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            }             

            if (!this.selectedCovenant) {
                this.$toasted.global.defaultError({msg: 'Informe o convênio.'})
                valid = false
            }

            if (valid) {
                this.generating = true
                this.textBtnPrint = 'Aguarde...'

                this.$store.commit("setCacheBatchsANS", { dateInitial: this.dateInitial, dateFinal: this.dateFinal, selectedCovenant: this.selectedCovenant, numberBatchFilter: this.numberBatchFilter })

                const url = `${baseApiUrl}/batch/filter/${this.dateInitial}&${this.dateFinal}&${encodeURIComponent(this.selectedCovenant.fantasyname)}&${this.numberBatchFilter ? this.numberBatchFilter : 'null'}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    this.batchs = res.data

                    Moment.locale('pt-br')

                    for (let b of this.batchs) {
                        b.date2 = Moment(b.date).format('L')

                        b.valueTotal2 = parseFloat(b.valueTotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})                        
                    
                        b._cellVariants = {
                            date2: b.nullified ? 'danger' : '',
                            'covenant.fantasyname': b.nullified ? 'danger' : '',
                            numberBatch: b.nullified ? 'danger' : '',
                            numberTotalGuides: b.nullified ? 'danger' : '',
                            valueTotal2: b.nullified ? 'danger' : '',
                            'actions': b.nullified ? 'danger' : ''
                        }
                    }

                    this.generating = false
                    this.textBtnPrint = 'Filtrar'                    
                }).catch(showError)              
            }
        },
        newBatch() {
            this.guidesSelected = []

            this.$root.$emit('bv::show::modal', "guides", null)
        },
        searchBatchGuides() {
            let valid = true

            if (this.dateInitialGuide == '' || this.dateInitialGuide == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data Inicial.'})
                valid = false
            }

            if (this.dateFinalGuide == '' || this.dateFinalGuide == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data Final.'})
                valid = false
            }

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitialGuide)
            let dtFinal = Moment(this.dateFinalGuide)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            }             

            if (!this.selectedCovenantGuide) {
                this.$toasted.global.defaultError({msg: 'Informe o convênio.'})
                valid = false
            }

            if (valid) {
                this.generating = true
                this.textBtnPrint = 'Aguarde...'

                const url = `${baseApiUrl}/guidesANS/filter/unprocessed/${this.dateInitialGuide}&${this.dateFinalGuide}&${encodeURIComponent(this.selectedCovenantGuide.cnpj)}&${this.numberGuideFilter ? this.numberGuideFilter : 'null'}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    this.guidesFilter = res.data

                    Moment.locale('pt-br')

                    for (let g of this.guidesFilter) {
                        g.date2 = Moment(g.date).format('L')

                        g.valueTotal2 = parseFloat(g.valueTotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})                        

                        let guideANS = JSON.parse(g.xmlToJson)

                        g.patientName = guideANS.guides[0].nomeBeneficiario

                        g.numberGuide = guideANS.guides[0].numeroGuiaPrestador
                        
                        g._cellVariants = {
                            date2: g.nullified ? 'danger' : '',
                            'covenant.fantasyname': g.nullified ? 'danger' : '',
                            numberBatch: g.nullified ? 'danger' : '',
                            numberGuide: g.numeroGuiaPrestador ? 'danger' : '',
                            numberTotalGuides: g.nullified ? 'danger' : '',
                            valueTotal2: g.nullified ? 'danger' : '',
                            'actions': g.nullified ? 'danger' : ''
                        }
                    }

                    this.generating = false
                    this.textBtnPrint = 'Filtrar'                    
                }).catch(showError)              
            }
        },
        onRowSelectedGuides(listSelected) {
            let finded = false

            for (let l of listSelected) {
                finded = false

                for (let g of this.guidesSelected) {
                    if (g.numberGuide === l.numberGuide) {
                        finded = true
                        break
                    } 
                }

                if (!finded) {
                    this.guidesSelected.push(l)
                }
            }
        },
        loadSettings() {
            if (!JSON.parse(localStorage.getItem('__connect_user')).admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.settings = res.data                
            }).catch(showError)                      
        },
        deleteGuide(index) {
            this.$bvModal.msgBoxConfirm('Deseja remover esta guia?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {      
                if (value) {
                    this.guidesSelected.splice(index, 1)
                }
            })
        },
        deleteGuidesBatch(index) {
            this.$bvModal.msgBoxConfirm('Deseja remover esta guia?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {      
                if (value) {
                    this.guidesDeleteSelected.splice(index, 1)
                }
            })
        },
        sendDeleteGuidesBatch() {
            this.isLoading = true

            const data = {
                idBatch: this.idBatchDeleted,
                guides: this.guidesDeleteSelected,
                covenant: this.selectedCovenant,
                valueTotal: this.valueTotalDeleted
            }

            axios.post(`${baseApiUrl}/batch/remove/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
            .then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.searchBatchs()
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r,  text: r.fantasyname })
                }
            }).catch(showError)
        },
        generateXml(itemSelected) {
            const data = {
                id: itemSelected._id
            }

            const url = `${baseApiUrl}/batch/regenerateXmlANS/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, data).then(res => {
                let json = JSON.parse(res.data.xmlToJson)
                const content = res.data.xml
                const element = document.createElement("a")
                const file = new Blob([content], {type: "text/plain"})
                element.href = URL.createObjectURL(file)
                element.download = `${json.header.registroANS}${json.header.dataRegistroTransacao.substring(0,4)}${json.header.dataRegistroTransacao.substring(5,7)}${json.header.sequencialTransacao}.xml`
                element.click()
            }).catch(showError)
        },
        reportXml(itemSelected) {
            try {
                let listTopics = []
                let listTotalization = []
                let listHeaders = []

                listHeaders.push({
                    text: 'Nº Remessa',
                    value: itemSelected.numberBatch
                })

                listHeaders.push({
                    text: 'Convênio',
                    value: itemSelected.covenant.fantasyname
                })

                listHeaders.push({
                    text: 'Data Geração XML',
                    value: Moment(itemSelected.date).format('L')
                })

                let listGuides = []

                let totalGeneral = 0.0

                this.guidesANS = JSON.parse(itemSelected.xmlToJson)

                for (let g of this.guidesANS.guides) {
                    const map = Object.create(null);

                    for (const str of this.guidesANS.allGuides) {
                        if (str.numeroGuiaPrestador == g.numeroGuiaPrestador) {
                            if (map[str.descricaoProcedimento]) {
                                map[str.descricaoProcedimento] += str.valorTotal;
                            } else {
                                map[str.descricaoProcedimento] = str.valorTotal;
                            }

                            totalGeneral += str.valorTotal
                        }
                    }

                    for (const [key, value] of Object.entries(map)) {
                        listGuides.push({
                            numeroGuiaPrestador: g.numeroGuiaPrestador,
                            numeroCarteira: g.numeroCarteira,
                            nomeBeneficiario: g.nomeBeneficiario,
                            tipoAtendimento: key,
                            valorTotal: Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value)
                        })
                    }
                }

                listTotalization.push({
                    text: 'Valor Total Remessa (R$)',
                    value: Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(totalGeneral)
                })

                listTotalization.push({
                    text: 'Quantidade de Guias',
                    value: itemSelected.numberTotalGuides
                })                

                let contentPdf = {
                    title: 'LOTE DE GUIAS - ANS',
                    format: 'A4',
                    orientation: 'portrait',
                    content: [
                        {
                            type: 'table',
                            topicFields: listTopics,
                            fields: [
                                {
                                    original: 'numeroGuiaPrestador',
                                    exibition: 'Guia'
                                },
                                {
                                    original: 'numeroCarteira',
                                    exibition: 'Matrícula'
                                },
                                {
                                    original: 'nomeBeneficiario',
                                    exibition: 'Paciente'
                                },
                                {
                                    original: 'tipoAtendimento',
                                    exibition: 'Atendimento'
                                },                                                      
                                {
                                    original: 'valorTotal',
                                    exibition: 'Valor'
                                }
                            ],
                            list: listGuides,
                            listTotalization: listTotalization,
                            listHeaders: listHeaders
                        },
                    ]
                }

                const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
                    var file = new Blob([res.data], {type: 'application/pdf'})
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL)             
                }).catch(showError)    
            } catch(e) {
                // console.log(e)
            }
        },
        deleteBatch(itemSelected) {
            this.$bvModal.msgBoxConfirm(`Deseja excluir o lote ${itemSelected.numberBatch}?`, {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    this.guidesDeleteSelected = []

                    const xmlToJson = JSON.parse(itemSelected.xmlToJson)

                    this.idBatchDeleted = itemSelected._id
                    this.valueTotalDeleted = itemSelected.valueTotal

                    for (let g of xmlToJson.guides) {
                        g.xmlToJson = itemSelected.xmlToJson
                        this.guidesDeleteSelected.push(g)
                    }

                    this.$root.$emit('bv::show::modal', "guidesDelete", null)                  
                }                
            }).catch()
        },
      },
      mounted() {
          this.theme = localStorage.getItem('theme')
  
          if (this.$store.state.cacheBatchsANS) {
              this.dateInitial = this.$store.state.cacheBatchsANS.dateInitial,
              this.dateFinal = this.$store.state.cacheBatchsANS.dateFinal,
              this.numberBatchFilter = this.$store.state.cacheBatchsANS.numberBatchFilter,
              this.selectedCovenant = this.$store.state.cacheBatchsANS.selectedCovenant
              this.searchBatchs()
          }
  
          this.loadSettings()
          this.loadCovenants()
      }
  }
  </script>
  
  <style>
  .card-green {
    background-color: rgb(196, 235, 220);
  }
  
  .card-wine {
    background-color: rgb(255, 241, 241);
  }
  </style>